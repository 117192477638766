import React, {useEffect, useState, useRef, useReducer} from 'react'
import {useHistory} from 'react-router-dom'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  CircularProgress,
  Stack,
  Box,
  LinearProgress,
} from '@mui/material'
import {styled} from '@mui/material/styles'
import SearchBar from 'material-ui-search-bar'
import {first, includes, isEmpty, pullAt} from 'lodash'
import {useStripe, useElements, CardNumberElement} from '@stripe/react-stripe-js'

import {ModalType} from 'enums'
import {ShippingMenu} from 'assets/data/options/inventory_filter_menu'
import DetailView from 'components/DetailView'
import {useShipments, useDiscounts} from 'service/hook'
import {CopytButton} from 'views_v2/lib/snippets'
import {useGlobalStore} from 'provider/global_store/hook'
import {formatName} from 'util/string_utils'
import mw from './middleware'
import ShippingModals from './ShippingModals'
import ShippingTableRow from './ShippingTableRow'
import FilterToolbar from './FilterToolbar'

export const StyledSearchBar = styled(SearchBar)`
  max-width: 300px;
  height: 40px !important;
`

const defaultParcelFields = {
  weight: null,
  length: null,
  width: null,
  height: null,
  distance_unit: 'in',
  mass_unit: 'lb',
}

const defaultAddressesFields = {
  recipient: {},
  sender: {},
}

const Shipping = () => {
  const {user, hasMop, userRefech} = useGlobalStore()
  const stripe = useStripe()
  const elements = useElements()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const [modalType, setModalType] = useState(ModalType.UNDEFINED)
  const [shipmentType, setShipmentType] = useState('order-fulfillment')
  const [selectedLocation, setSelectedLocation] = useState()
  const [payload, setPayload] = useState({items: [], boxItems: []})
  const [consignee, setConsignee] = useState({value: null, label: null, location: 'default'})
  const [queries, setQueries] = useState({})
  const [sIds, setSIds] = useState([])
  const [transactions, setTransactions] = useState([])
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [filter, setFilter] = useState({
    skip: 0,
    take: 100,
    page: 0,
  })
  const [parcel, setParcel] = useState(defaultParcelFields)
  const [recipientId, setRecipientId] = useState()

  const [addresses, setAddresses] = useState(defaultAddressesFields)

  const [billingDetails, setBillingDetails] = useState({
    name: null,
    address: null,
    city: null,
    state: null,
    zip: null,
  })

  const {
    isLoading,
    isFetching,
    isLoadingGetShipmentById,
    shipments,
    setShipment,
    shipmentsCount,
    createShipment,
    updateShipment,
    isUpdatingShipment,
    isSuccessPurchaseShipment,
    isSuccessShipmentById,
    isSuccessPaymentMethod,
    downloadShipmentLabels,
    labels,
    generateShipment,
    isGeneratingShipment,
    shipment,
    purchaseShipment,
    getShipmentById,
    isPurchasingShipment,
    createStripePayment,
    deleteShipment,
    isDeletingShipment,
    isCreatingShipment,
    shippingDetails,
    applyShipmentDiscount,
    isApplyingShipmentDiscount,
    generateShipmentError,
    shipmentConstant,
    createAnotherShipment,
    isCreatingAnotherShipment,
    createAnotherShipmentId,
    setCreateAnotherShipmentId,
  } = useShipments(filter, queries)

  const {discounts, applyDiscount, isApplyingDiscount} = useDiscounts()

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    const sortDirection = isAsc ? 'desc' : 'asc'
    const sortBy = property === 'listPriceRange' ? 'price' : property

    setOrder(sortDirection)
    setOrderBy(property)
    if (sortDirection !== order || orderBy !== property) {
      setQueries({
        ...filter,
        ...queries,
        sortBy,
        sortDirection,
      })
    }
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const handleChangePage = (event, newPage) => {
    setFilter((prevState) => ({
      ...prevState,
      page: newPage,
      skip: prevState.take * newPage,
    }))
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setFilter({...filter, page: 0})
  }

  const onSaveChanges = async (p) => {
    if (p?.createShipment) {
      await createShipment(p?.createShipment)
      setModalType(ModalType.PACKAGE_DETAILS)
    }
    if (p?.createAnotherShipment) {
      await createAnotherShipment({id: createAnotherShipmentId, ...p?.createAnotherShipment})
      setModalType(ModalType.PURCHASE_LABEL)
    } else if (p?.generateShipment) {
      if (parcel?.name) delete parcel.name

      let signature_confirmation = undefined
      if (p?.generateShipment?.signature_confirmation) {
        if (p?.generateShipment?.signature_confirmation?.value) {
          signature_confirmation = 'STANDARD'
        }
      } else {
        signature_confirmation = shipment?.shippo?.shipment?.extra?.signature_confirmation
      }

      let insurance = undefined
      if (p?.generateShipment?.insurance) {
        if (p?.generateShipment?.insurance?.value) {
          insurance = p?.generateShipment?.insurance?.value
        }
      } else {
        insurance = shipment?.shippo?.shipment?.extra?.insurance
      }

      const payload = {
        address_from: mw.createAddress(undefined, addresses?.sender),
        address_to: mw.createAddress(undefined, addresses?.recipient),
        parcels: [parcel],
        extra: {
          insurance: insurance,
          signature_confirmation: signature_confirmation,
        },
        async: false,
      }
      await generateShipment({payload, senderId: user.id, recipientId})
      setModalType(ModalType.PURCHASE_LABEL)
    } else if (p?.purchase) {
      if (!user?.private?.customerId || !user?.private?.paymentMethodId) {
        setModalType(ModalType.PAYMENT_METHOD)
        return
      }
      const payload = {
        ...p?.purchase,
        payment: {
          paymentMethodId: user?.private?.paymentMethodId,
          name: billingDetails?.name || user?.company?.name,
          email: user?.email,
          customerId: user?.private?.customerId,
        },
      }
      const response = await purchaseShipment(payload)
      if (response === false) return
      setModalType(ModalType.LABEL_PURCHASED)
    } else if (p?.createAnother) {
      await getShipmentById(p?.createAnother)
      setModalType(ModalType.PURCHASE_LABEL)
    } else if (p?.paymentMethod) {
      if (!stripe || !elements) return

      const cardElement = elements.getElement(CardNumberElement)
      if (cardElement && !hasMop) {
        await createStripePayment({cardElement, billingDetails, email: user?.email, stripe})
      }

      userRefech()
    }
  }

  const onSelectShipment = (transactionId) => {
    const arr = transactions
    const index = arr.findIndex((s) => s === transactionId)
    if (index > -1) {
      pullAt(arr, index)
    } else {
      arr.push(transactionId)
    }
    setTransactions(arr)
    forceUpdate()
  }

  useEffect(() => {
    if (isSuccessShipmentById) {
      if (isEmpty(shipment?.recipientAddress) || isEmpty(shipment?.senderAddress || shipment?.parcel)) {
        return
      }
      setAddresses({
        recipient: {
          ...shipment?.recipientAddress,
        },
        sender: {
          ...shipment?.senderAddress,
        },
      })
      setParcel(Object.keys(shipment?.parcel || {}).length ? shipment?.parcel : defaultParcelFields)
    }
  }, [shipment, isSuccessShipmentById])

  useEffect(() => {
    if (isSuccessPaymentMethod) {
      setModalType(ModalType.PURCHASE_LABEL)
    }
  }, [isSuccessPaymentMethod])

  useEffect(() => {
    if (modalType == -1) {
      setShipment(null)
      setParcel(defaultParcelFields)
      setAddresses(defaultAddressesFields)
    }
  }, [modalType])

  useEffect(() => {
    if (isSuccessPurchaseShipment) {
      if (hasMop) {
        // setModalType(ModalType.LABEL_PURCHASED)
      } else {
        setModalType(ModalType.PAYMENT_METHOD)
      }
    }
  }, [shipment, isSuccessPurchaseShipment])

  useEffect(() => {
    if (labels?.url && !isEmpty(transactions)) {
      window.open(labels?.url, '_blank')
      setTimeout(() => {
        setTransactions([])
      }, 1000)
    }
  }, [labels])

  return (
    <div className="p-detail-flex">
      <Stack
        direction="row"
        gap={2}
        justifyContent="end"
        className="p-detail-toolbar no-print v2"
        backgroundColor="var(--lighter)"
      >
        <CopytButton
          onClick={() => setModalType(ModalType.DISCOUNT_CODE)}
          variant="outline"
          color="primary"
          style={{padding: '6px 16px', borderRadius: '4px'}}
        >
          Discount Code
        </CopytButton>
        <CopytButton
          onClick={() => setModalType(ModalType.CREATE_BOX_PRESET)}
          variant="outline"
          color="primary"
          style={{padding: '6px 16px', borderRadius: '4px'}}
        >
          Create Box Preset
        </CopytButton>
      </Stack>

      <DetailView.Panel style={{flex: 1, height: '0%'}}>
        <Stack direction="column" height="100%" flex={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
            bgcolor="var(--primary-light)"
            borderBottom="1px solid var(--success)"
            padding={1}
            alignItems="center"
          >
            <Stack direction="row">
              <StyledSearchBar
                placeholder="Search"
                className="search-item"
                disabled={isLoading || isLoadingGetShipmentById}
                cancelOnEscape
                onCancelSearch={() => setQueries({search: ''})}
                onRequestSearch={(s) => setQueries({search: s})}
              />
              <FilterToolbar
                {...{queries}}
                filterMenu={[
                  {id: 'type', title: 'Shipment Type'},
                  {id: 'status', title: 'Tracking Status'},
                  {id: 'refund', title: 'Refund Status'},
                ]}
                disabled={isLoading}
                onSaveChanges={(filter) => setQueries(filter)}
                onSetQueries={setQueries}
                _style={{
                  sx: {
                    width: '100px',
                    height: '40px',
                    color: 'var(--primary) !important',
                    fontWeight: 600,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  },
                }}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <CopytButton
                variant={isEmpty(transactions) ? '' : 'contained'}
                disabled={isEmpty(transactions)}
                onClick={async (event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  const {data} = await downloadShipmentLabels({transactions})
                  window.open(data?.data?.url, '_blank')
                }}
                color="primary"
                style={{padding: '6px 16px', borderRadius: '4px'}}
              >
                Download Labels
              </CopytButton>

              <CopytButton
                onClick={() => setModalType(ModalType.CREATE_LABEL)}
                variant="contained"
                color="primary"
                style={{padding: '6px 16px', borderRadius: '4px'}}
              >
                Create Label
              </CopytButton>
            </Stack>
          </Stack>

          <TableContainer component={Paper} style={{height: '90%'}}>
            {isFetching ? <LinearProgress color="secondary" /> : <Stack height="4px"></Stack>}
            <Table stickyHeader aria-label="collapsible table" className="table-product-connection">
              <TableHead style={{background: 'var(--primary-light)'}}>
                <TableRow>
                  <TableCell flex={0.2} sx={{backgroundColor: 'var(--primary-light)'}} />
                  {ShippingMenu.map((menu) => {
                    if (!menu.isHide) {
                      return (
                        <TableCell
                          key={menu.id}
                          sortDirection={orderBy === menu.id ? 'asc' : false}
                          disabled={isLoading}
                          flex={menu?.flex}
                          sx={{
                            lineHeight: 1,
                            backgroundColor: 'var(--primary-light)',
                          }}
                        >
                          <TableSortLabel
                            active={orderBy === menu.id}
                            direction={orderBy === menu.id ? order : 'asc'}
                            onClick={createSortHandler(menu.id)}
                            sx={{
                              color: 'var(--primary) !important',
                            }}
                          >
                            {menu?.alternateTitle || menu?.title}
                          </TableSortLabel>
                        </TableCell>
                      )
                    }
                  })}
                  <TableCell flex={0.2} sx={{backgroundColor: 'var(--primary-light)'}} />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={10} align="center" style={{height: '55vh', border: 'none'}}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : !isEmpty(shipments) ? (
                  shipments?.map((row, index) => (
                    <ShippingTableRow
                      key={row.id}
                      {...{
                        row,
                        user,
                        transactions,
                        onSelectShipment,
                        onSaveChanges,
                        setModalType,
                        getShipmentById,
                        deleteShipment,
                        isDeletingShipment,
                        createAnotherShipment,
                        isCreatingAnotherShipment,
                        setCreateAnotherShipmentId,
                      }}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center" style={{height: '55vh'}}>
                      No Data Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!isEmpty(shipments) ? (
            <div
              style={{
                width: '100%',
                height: 60,
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'var(--primary-light)',
                alignItems: 'stretch',
              }}
            >
              <TablePagination
                style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, overflow: 'hidden'}}
                count={shipmentsCount || 0}
                rowsPerPage={100}
                rowsPerPageOptions={[100]}
                page={filter?.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={() => {}}
              />
            </div>
          ) : null}
        </Stack>

        <ShippingModals
          {...{
            addresses,
            setRecipientId,
            billingDetails,
            consignee,
            modalType,
            payload,
            parcel,
            shipmentType,
            onSaveChanges,
            isGeneratingShipment,
            isPurchasingShipment,
            selectedLocation,
            setAddresses,
            setBillingDetails,
            setConsignee,
            setModalType,
            setPayload,
            setShipmentType,
            setSelectedLocation,
            setParcel,
            shipment,
            updateShipment,
            isUpdatingShipment,
            isCreatingShipment,
            shippingDetails,
            discounts,
            applyShipmentDiscount,
            isApplyingShipmentDiscount,
            applyDiscount,
            isApplyingDiscount,
            generateShipmentError,
            shipmentConstant,
            createAnotherShipment,
            isCreatingAnotherShipment,
            createAnotherShipmentId,
            setCreateAnotherShipmentId,
          }}
          isLoading={isLoading || isLoadingGetShipmentById}
          // shipment={shipmentId ? shipments?.find((s) => s?.id === shipmentId) : shipment}
        />
      </DetailView.Panel>
    </div>
  )
}

export default Shipping
