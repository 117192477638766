import React, {useState, useEffect} from 'react'
import {get, isEmpty} from 'lodash'
import {Box, Typography, Checkbox, FormControlLabel, Stack} from '@mui/material'

import FieldGroup from 'views_v2/lib/snippets/FieldGroup'
import onKeyPress from 'views_v2/lib/common/onKeyPress'
import {CopytStyledButton} from 'views_v2/lib/snippets'

const MdlAddress = (p) => {
  const [errorMessage, setErrorMessage] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [validName, setValidName] = useState(false)
  const [data, setData] = useState(locForUpdate)
  const [isDefaultLocation, setIsDefaultLocation] = useState(false)
  const isUpdate = get(p, 'isUpdate', false)
  const locForUpdate = isEmpty(get(p, 'locForUpdate.target')) ? get(p, 'locForUpdate', null) : null

  const onSubmit = (e) => {
    setIsSubmitting(true)
    let formError = null

    const [validLocName, errorMessage] = validateLocName(get(data, 'name', ''))
    setValidName(validLocName)
    formError = errorMessage

    if (
      isEmpty(get(data, 'name', '')) ||
      isEmpty(get(data, 'city', '')) ||
      isEmpty(get(data, 'state', '')) ||
      isEmpty(get(data, 'zipcode', ''))
    )
      formError = 'is required'

    setErrorMessage(formError)

    if (isEmpty(formError)) {
      p?.onSaveChanges({...data, isDefault: isDefaultLocation})
      p?.onCloseModal()
    }
  }

  const handleChange = (e) => {
    if (typeof e === 'boolean') {
      setData({...data, isDefault: e})
    } else {
      const {name, value} = e.target
      setData({...data, [name]: value})

      if (name === 'name' && !isEmpty(value)) setValidName(true)
    }
  }

  useEffect(() => {
    if (locForUpdate && isUpdate) {
      setData(locForUpdate)
      setIsDefaultLocation(locForUpdate.isDefault)
    }
  }, [locForUpdate, isUpdate])

  const validateLocName = (locationName) => {
    let valid = true
    let errorMessage = ''
    const locNames = p?.locations.map((loc) => `${loc?.name}`.toLowerCase())
    if (locNames.includes(locationName.toLowerCase())) {
      // invalid if new loc name is already taken or
      // valid if it's the same
      errorMessage = ` is already specificied. Please choose another location name.`
      if (`${locForUpdate?.name}`.toLowerCase() === 'default') {
        valid = locationName.toLowerCase() === 'default'
        return [valid, valid ? '' : errorMessage]
      }
      valid = locationName.toLowerCase() === `${locForUpdate?.name}`.toLowerCase()
      return [valid, valid ? '' : errorMessage]
    }

    return [!isEmpty(locationName), isEmpty(locationName) ? errorMessage : '']
  }

  const hasError = isSubmitting && errorMessage
  const hasDefaultLocation = !isEmpty(p?.locations) ? p?.locations?.some((s) => s?.isDefault) : false

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            {isUpdate ? 'Update' : 'New'} Store Location
          </Typography>
        </Box>
      </Box>

      <Box className="modal-body" padding={0} my={2}>
        <Stack px={2} mb={2}>
          <FieldGroup
            sLabel="Location Name"
            value={data?.name ?? ''}
            maxLength={20}
            hasError={hasError && !validName}
            errorMessage={`name ${errorMessage}`}
            name="name"
            type="text"
            placeholder="Location Name"
            onChange={handleChange}
          />
          <FieldGroup
            sLabel="Address"
            value={data?.address ?? ''}
            maxLength={30}
            name="address"
            type="text"
            placeholder="Address"
            onChange={handleChange}
          />
          <FieldGroup
            sLabel="Street line 2 (Optional)"
            value={data?.street2 ?? ''}
            maxLength={30}
            name="street2"
            type="text"
            placeholder="Street line 2 (Optional)"
            onChange={handleChange}
          />
          <FieldGroup
            sLabel="City"
            value={data?.city ?? ''}
            maxLength={20}
            hasError={hasError && isEmpty(get(data, 'city', ''))}
            errorMessage={`city ${errorMessage}`}
            name="city"
            type="text"
            placeholder="City"
            onChange={handleChange}
          />
          <FieldGroup
            sLabel="State"
            value={data?.state ?? ''}
            maxLength={20}
            hasError={hasError && isEmpty(get(data, 'state', ''))}
            errorMessage={`state ${errorMessage}`}
            name="state"
            type="text"
            placeholder="State"
            onChange={handleChange}
          />
          <FieldGroup
            sLabel="Zip Code"
            maxLength={20}
            hasError={hasError && isEmpty(get(data, 'zipcode', ''))}
            errorMessage={`zip code ${errorMessage}`}
            type="number"
            min="0"
            value={data?.zipcode ?? ''}
            onKeyPress={(e) => onKeyPress(e, 'e')}
            name="zipcode"
            placeholder="Zip Code"
            onChange={handleChange}
          />
          <FormControlLabel
            name="isDefault"
            disabled={hasDefaultLocation && !data?.isDefault}
            control={<Checkbox checked={isDefaultLocation || false} />}
            onChange={() => setIsDefaultLocation(!isDefaultLocation)}
            sx={{
              lineHeight: '15px',
              fontSize: '0.85rem',
            }}
            label={`${
              data?.isDefault
                ? 'Current default location. Uncheck to remove.'
                : hasDefaultLocation
                ? 'Remove existing default location to select a new one.'
                : 'Set as default location'
            }`}
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={2}
          borderTop="1px solid #eee"
          spacing={2}
        >
          <CopytStyledButton variant="contained" color="primary" onClick={() => onSubmit()}>
            Submit
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlAddress
