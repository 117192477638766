import React, {useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Box} from '@mui/material'
import {Row, Col} from 'reactstrap'
import axios from 'axios'
import {useGlobalStore} from 'provider/global_store/hook'
import {toLower} from 'lodash'
import {useAuth0} from '@auth0/auth0-react'
import {captureException} from '@sentry/react'

import {useListing} from 'service/hook/use_listing'
import {getConsignEmail} from 'util/model/product'
import {isConsigned} from 'util/model/product'
import {ModalType} from 'enums'
import {paths} from 'constant/api'
import DetailView from 'components/DetailView'
import {InventoryHeader} from 'views_v2/modules/Inventory/components/common'
import InventoryModals from 'views_v2/modules/Inventory/components/InventoryModals'
import {
  ConsignorInformation,
  InventoryPricing,
  InventoryTracking,
} from 'views_v2/modules/Inventory/components/cards'
import {CopytButton, LoadingBar} from 'views_v2/lib/snippets'
import {ListingDetails, ListingInventoryDetails} from 'views_v2/modules/Listings'
import {ListingOverView} from './component/overview'
import {ListingAssets} from './component/images'
import {ListingShipping} from './component/shipping'

const ViewSold = (p) => {
  const {id} = useParams()
  const history = useHistory()

  const {getAccessTokenSilently} = useAuth0()
  const [formFields, setFormFields] = useState({quantity: 1})
  const [isLoading, setIsLoading] = useState(false)
  const {isEnterprise} = useGlobalStore()
  const [modalType, setModalType] = useState(ModalType.UNDEFINED)

  const {listingData, item: listingQuery, error} = useListing(id, error ? {} : {refetchInterval: 5000})

  const routeChange = (route, data) => {
    history.push({pathname: route, data: data})
  }

  const markAsProcessed = async () => {
    setIsLoading(true)
    const token = await getAccessTokenSilently()
    try {
      await axios.post(
        `${paths.listing}/status`,
        {listingId: id, status: 'processed'},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      listingQuery.refetch().finally(() => setIsLoading(false))
    } catch (e) {
      captureException(e)
      setDisableLoading(false)
    }
  }

  const cancelSale = async () => {
    setIsLoading(true)
    const token = await getAccessTokenSilently()
    try {
      await axios.delete(`${paths.listing}/many-sale`, {
        data: {ids: [id]},
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setIsLoading(false)
      routeChange(`/admin/inventory/viewInventory`)
    } catch (e) {
      setIsLoading(false)
    }
  }

  if (error) {
    return (
      <div className="content">
        Failed.
        <hr />
        <CopytButton onClick={() => history.goBack()} variant="contained" color="error">
          Back
        </CopytButton>
      </div>
    )
  } else {
    if (!listingData) {
      return <LoadingBar />
    }
  }

  const product = listingData?.product

  return (
    <Box className="p-detail-flex inventory">
      <Box className="p-detail-toolbar no-print v2">
        <InventoryHeader
          {...{markAsProcessed, setModalType, isLoading}}
          tabPanel={toLower(listingData.status)}
        />
      </Box>
      <DetailView.Panel scrollable>
        <Row style={{marginTop: '24px', flex: 1}} className="mainRow">
          <Col sm="12" md="10" lg="7" style={{paddingLeft: '0px', paddingRight: '0px'}}>
            <Row>
              <Col sm="12" md="6" lg="6">
                <ListingOverView product={product} />
              </Col>
              <Col sm="12" md="6" lg="6">
                <ListingInventoryDetails {...{product}} readOnly />
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="12" lg="6">
                <ListingAssets assets={product.assets} />
              </Col>
              <Col style={{paddingLeft: '0px!important'}} sm="12" md="6" lg="6">
                <Row>
                  <ListingShipping product={product} isReadOnly={true} />
                </Row>
                <Row>
                  <InventoryPricing
                    {...{isEnterprise}}
                    isConsigned={isConsigned(product)}
                    formFields={Object.assign(product, {
                      desiredReturn: listingData?.product?.desiredReturn,
                      price: isConsigned(product) && isEnterprise ? '' : product?.price,
                    })}
                    readOnly
                    isDesiredReturnReadOnly
                  />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col style={{paddingLeft: '0px!important'}} sm="12" md="6" lg="5">
            <ListingDetails listing={listingData} readOnly onChange={(w) => console.log(w)} />
            {isEnterprise && (
              <InventoryTracking
                {...{
                  isEnterprise,
                  setFormFields,
                }}
                isReadOnly
                formFields={product}
                location={product?.location}
                internalSku={product?.internalSku}
              />
            )}
            {isConsigned(product) && (
              <ConsignorInformation
                platforms={listingData.listingPlatforms}
                setFormFields={setFormFields}
                consignor={product?.consign}
                consignorEmail={getConsignEmail(product)}
                feeStructure={product?.feeStructure}
                formFields={{
                  ...formFields,
                  desiredReturn: listingData?.product?.desiredReturn,
                }}
                isConsignorEmailReadOnly
                isFeeStructureReadOnly
              />
            )}
          </Col>
        </Row>
        <InventoryModals
          {...{
            cancelSale,
            modalType,
            setModalType,
          }}
          isProcessed={listingData.status === 'processed'}
        />
      </DetailView.Panel>
    </Box>
  )
}

export default ViewSold
