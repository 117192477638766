import React from 'react'
import {Box, Grid, Stack} from '@mui/material'
import SearchBar from 'material-ui-search-bar'
import styled from 'styled-components'
import {isEmpty} from 'lodash'

import {
  UnlistedTableFilterMenu,
  ListedTableFilterMenu,
  SoldTableFilterMenu,
  ProcessedTableFilterMenu,
} from 'assets/data/options/inventory_filter_menu'
import {CopytButton} from 'views_v2/lib/snippets'
import {getCurrency} from 'util/model/setting'
import {_getValue} from 'util/string_utils'
import FilterToolbar from './FilterToolbar'
import OptionsToolbar from './OptionsToolbar'

const SearchToolbar = styled(SearchBar)(() => ({
  height: '38px',
  width: '100% !important',
}))

const FilterMenu = {
  unlisted: UnlistedTableFilterMenu,
  listed: ListedTableFilterMenu,
  sold: SoldTableFilterMenu,
  processed: ProcessedTableFilterMenu,
}

const InventoryToolbar = ({
  filterMenu = [],
  queries = {},
  disableBtn = false,
  showDelete = false,
  showListAll = false,
  showBulkEdit = false,
  showImport = false,
  showAverage = false,
  showWithdraw = false,
  hasCustomDate = false,
  search = {
    disabled: false,
    value: '',
    onSearch: () => null,
  },
  average = {
    cost: 0,
    price: 0,
  },
  action,
  allItems = [],
  selectedTab = '',
}) => {
  const currency = getCurrency()
  const isMobile = window.innerWidth <= 900
  const filterMenuOptions =
    isEmpty(filterMenu) && selectedTab ? FilterMenu[selectedTab.toLowerCase()] : filterMenu

  return (
    <Grid
      container
      gap={1}
      justifyContent="space-between"
      backgroundColor="#faebf5"
      borderBottom="1px solid #a5709b"
      p={0.5}
      pb={0}
      className="inventory-table-toolbar"
    >
      <Grid item display="flex" alignItems="center">
        <Grid container gap={1}>
          <Grid item alignItems="center" display="flex">
            <SearchToolbar
              value={search?.value}
              disabled={search?.disabled}
              cancelOnEscape
              onCancelSearch={search?.onSearch}
              onRequestSearch={(s) => search?.onSearch(s)}
              style={{width: '100% !important'}}
            />
          </Grid>
          <Grid item>
            <FilterToolbar
              {...{queries}}
              filterMenu={filterMenuOptions}
              disabled={search?.disabled}
              onSaveChanges={action?.onSaveChanges}
            />
          </Grid>
          {isMobile && (
            <OptionsToolbar
              {...{
                showDelete,
                showBulkEdit,
                showListAll,
                showImport,
                showWithdraw,
                disableBtn,
                action,
                allItems,
                selectedTab,
              }}
            />
          )}
        </Grid>
      </Grid>
      {showAverage && !isMobile && (
        <Stack alignItems="center" direction="row" color="var(--primary)" fontWeight={600}>
          <Box mr={10}>
            Average Cost: <Box component="span">{currency.format(_getValue(average.cost))}</Box>
          </Box>
          <Box>
            Average Price: <Box component="span">{currency.format(_getValue(average.price))}</Box>
          </Box>
        </Stack>
      )}
      <Box display="flex" alignItems="center">
        {hasCustomDate && (
          <CopytButton
            onClick={action.onCustomDate}
            variant="contained"
            color="primary"
            style={{
              borderRadius: '6px',
              padding: '12px 16px',
              height: '35px',
            }}
          >
            Custom Date
          </CopytButton>
        )}
        {!isMobile && (
          <OptionsToolbar
            {...{
              showDelete,
              showBulkEdit,
              showListAll,
              showImport,
              showWithdraw,
              disableBtn,
              action,
              allItems,
              selectedTab,
            }}
          />
        )}
      </Box>
    </Grid>
  )
}

export default InventoryToolbar
