import React from 'react'
import PropTypes from 'prop-types'
import clx from 'classnames'

import CopytModal from 'components/CopytModal'
import {ModalType} from 'enums'
import MdlEcgReferralCode from 'views_v2/modules/Inventory/components/modal-contents/MdlEcgReferralCode'
import {
  MdlApply,
  MdlSelectApprovedItems,
  MdlWithdraw,
  MdlCreateBox,
  MdlEcgItemSubmission,
  MdlChooseConsignee,
  MdlListAllPlatform,
  MdlBarcodeTemplate,
} from './modal-contents'
import MdlConsignorProfile from './modal-contents/MdlConsignorProfile'

export const PlatformsModalType = {
  UNDEFINED: -1,
  APPLY: 0,
  ECG_REFERRAL_CODE: 1,
  CONSIGNOR_PROFILE: 2,
}

const ModalContents = ({children, common, focus, onCloseModal}) => {
  if (focus !== PlatformsModalType.UNDEFINED) {
    return React.Children.map(children, (c, i) => {
      if (c.props?.modalType === focus) {
        // Show modal based on the given modalType
        return React.cloneElement(c, {...common, onCloseModal})
      }
      return null
    })
  }
  return null
}

const PlatformsModal = (p) => {
  const {modalType, setModalType, modals, payload, setSelectionModel} = p
  const m = modals[modalType]

  const onClose = () => {
    setModalType(ModalType.UNDEFINED)
    setSelectionModel([])
  }

  return (
    <CopytModal
      className={m?.className || 'platforms-modal'}
      closeButton={m?.closeButton}
      id={m?.id}
      show={modalType > PlatformsModalType.UNDEFINED}
      size={m?.size || 'md'}
      toggle={() => setModalType(PlatformsModalType.UNDEFINED)}
    >
      <ModalContents
        focus={modalType}
        common={p}
        onCloseModal={() => setModalType(PlatformsModalType.UNDEFINED)}
      >
        <MdlApply modalType={PlatformsModalType.APPLY} />
        <MdlBarcodeTemplate modalType={ModalType.BARCODE_TEMPLATE} />
        <MdlChooseConsignee modalType={ModalType.CHOOSE_CONSIGNEE} />
        <MdlConsignorProfile modalType={ModalType.CONSIGNOR_PROFILE} />
        <MdlCreateBox modalType={ModalType.CREATE_BOX} />
        <MdlEcgReferralCode modalType={PlatformsModalType.ECG_REFERRAL_CODE} />
        <MdlEcgItemSubmission modalType={ModalType.ECG_ITEM_SUBMISSION} />
        <MdlListAllPlatform modalType={ModalType.LIST_ALL_PLATFORM} onCancel={onClose} />
        <MdlSelectApprovedItems modalType={ModalType.SELECT_APPROVED_ITEMS} />
        <MdlWithdraw modalType={ModalType.WITHDRAW} />
      </ModalContents>
    </CopytModal>
  )
}

PlatformsModal.propTypes = {
  modals: PropTypes.object,
}

PlatformsModal.defaultProps = {
  modals: {
    [PlatformsModalType.APPLY]: {
      className: clx('no-padding', 'apply-modal'),
      size: 'sm',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.BARCODE_TEMPLATE]: {
      className: clx('barcode-template-modal', 'no-padding'),
      size: 'sm',
    },
    [ModalType.WITHDRAW]: {
      className: clx('no-padding', 'withdraw-modal'),
      size: 'md',
      closeButton: {
        hidden: true,
      },
    },
    [PlatformsModalType.ECG_REFERRAL_CODE]: {
      className: clx('copyt_modal_container', 'apply-modal'),
      size: 'sm',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.SELECT_APPROVED_ITEMS]: {
      className: clx('no-padding'),
      size: 'lg',
    },
    [ModalType.CHOOSE_CONSIGNEE]: {
      className: clx('no-padding'),
      size: 'sm',
    },
    [ModalType.CREATE_BOX]: {
      className: clx('no-padding'),
    },
    [ModalType.ECG_ITEM_SUBMISSION]: {
      className: clx('no-padding'),
      size: 'md',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.LIST_ALL_PLATFORM]: {
      className: clx('no-padding'),
      size: 'xs',
    },
    [ModalType.CONSIGNOR_PROFILE]: {
      className: clx('copyt_modal_container', 'apply-modal'),
      size: 'sm',
      closeButton: {
        hidden: true,
      },
    },
  },
}

export default PlatformsModal
