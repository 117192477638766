import React, {useCallback, useContext, useState} from 'react'
import clx from 'classnames'
import {chain, countBy, debounce, isEmpty, map, mean, some, sum, sumBy, uniq} from 'lodash'
import SearchBar from 'material-ui-search-bar'
import {styled} from '@mui/material/styles'
import {
  ContentPaste as ContentPasteIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import {Box, Card, CardMedia, Chip, Stack, Typography} from '@mui/material'
import moment from 'moment'

import DetailView from 'components/DetailView'
import {ModalType} from 'enums'
import {getCurrency} from 'util/model/setting'
import {isConsigned} from 'util/model/product'
import {_getValue} from 'util/string_utils'
import {useGlobalStore} from 'provider/global_store/hook'
import {copyToClipboard} from 'views_v2/lib/common'
import {CopytLabel, CopytTooltip, LoadingBar} from 'views_v2/lib/snippets'
import {EmptyInventory, InventoryToolbar} from 'views_v2/modules/Inventory/snippets'
import {generateExportData} from 'views_v2/modules/Inventory/hooks/util'
import {SearchContext, TabPanel} from 'views_v2/modules/Inventory'
import {ReactComponent as ConsignedBannerSvg} from '../../../../../../assets/img/consigned-banner_v2.svg'
import UnlistedTable from './UnlistedTable'
import ListedTable from './ListedTable'
import SoldTable from './SoldTable'
import ProcessedTable from './ProcessedTable'

export const StyledSearchBar = styled(SearchBar)`
  margin: 10px auto;
  max-width: 300px;
  float: left;
  opacity: 0.8;
  cursor: no-drop;
`

const CardText = styled(Typography)`
  display: ${(props) => props.display || 'flex'};
  justify-content: flex-start;
  color: #0009;
  font-size: ${(props) => props.fontSize || '0.875rem'};
  gap: 10px;
  white-space: ${(props) => props.whiteSpace || 'nowrap'};
  overflow: hidden;
  text-overflow: ellipsis;
`

const InventoryCardList = (p) => {
  const {
    disableBtn,
    data,
    isFetching,
    handleChange,
    queries,
    tabPanel,
    tabString,
    routeChange,
    setModalType,
    showWithdraw,
    onNavigateUrl,
    listSelections,
    metricsQuery,
  } = p
  const {isEnterprise} = useGlobalStore()
  const [showUnlistedTableIndex, setShowUnlistedTableIndex] = useState(null)
  const isMobile = window.innerWidth <= 850
  const currency = getCurrency()

  const handleCardClick = (index) => {
    if (showUnlistedTableIndex === index) {
      setShowUnlistedTableIndex(null)
    } else {
      setShowUnlistedTableIndex(index)
    }
  }

  const search = useContext(SearchContext)
  const onSearch = useCallback(
    debounce((searchKey) => {
      if (tabString === 'unlisted') search.setUnlistedSearchText(searchKey), 1000
      else if (tabString === 'listed') search.setListedSearchText(searchKey), 1000
      else if (tabString === 'sold') search.setSoldSearchText(searchKey), 1000
      else if (tabString === 'processed') search.setProcessedSearchText(searchKey), 1000
    }),
    [],
  )

  const TableView = (index, cardData) => {
    if (index === showUnlistedTableIndex) {
      switch (tabPanel) {
        case TabPanel.LISTED:
          return <ListedTable {...p} isCardList cardItem={cardData} />
        case TabPanel.SOLD:
          return <SoldTable {...p} isCardList cardItem={cardData} />
        case TabPanel.PROCESSED:
          return <ProcessedTable {...p} isCardList cardItem={cardData} />
        default:
          return <UnlistedTable {...p} isCardList cardItem={cardData} />
      }
    }
  }

  return (
    <React.Fragment>
      <Stack alignItems="center" mt={2} px={3}>
        <Box width="100%">
          <InventoryToolbar
            {...{disableBtn, queries}}
            showBulkEdit={tabPanel === TabPanel.UNLISTED && !isEmpty(listSelections)}
            showAverage={!isMobile && tabPanel === TabPanel.UNLISTED}
            showDelete={tabPanel === TabPanel.UNLISTED}
            showListAll={!isMobile && tabPanel === TabPanel.UNLISTED && !isEmpty(listSelections)}
            showWithdraw={
              showWithdraw && (tabPanel === TabPanel.UNLISTED || tabPanel === TabPanel.LISTED)
            }
            average={{
              cost: metricsQuery?.data?.averageCost || 0,
              price: metricsQuery?.data?.averagePrice || 0,
            }}
            search={{
              disabled: isFetching || isEmpty(data),
              value: search[`${tabString}SearchText`],
              onSearch: onSearch,
            }}
            action={{
              onNavigateUrl: onNavigateUrl,
              onCustomDate: () => setModalType(ModalType.DATE_PICKER),
              onDeleteAll: () => setModalType(ModalType.DELETE_ALL),
              onListMultiple: () => setModalType(ModalType.LIST_ALL_PLATFORM),
              onBulkEdit: () => setModalType(ModalType.BULK_EDIT),
              onSaveChanges: p?.onSaveChanges,
            }}
            allItems={generateExportData(data, tabPanel)}
            selectedTab={tabString}
            hasCustomDate={tabPanel === TabPanel.SOLD || tabPanel === TabPanel.PROCESSED}
          />
          {isFetching && <LoadingBar type="linear" />}
        </Box>
      </Stack>
      <DetailView.Panel
        scrollable
        style={{backgroundColor: '#faebf5', marginLeft: '24px', marginRight: '24px'}}
      >
        {isEmpty(data) && !isFetching ? (
          <EmptyInventory
            {...{disableBtn, handleChange, routeChange}}
            type={tabString === 'unlisted' ? 'unlisted' : 'listing'}
            tabSelection={tabString}
            TabHandler={() => null}
          />
        ) : (
          <Stack spacing={1} direction="column">
            {!isEmpty(data) &&
              data.map((item, index) => {
                const itemData = !isEmpty(item) ? item?.items : []
                const isMultiple = some(
                  itemData?.length > 1 && countBy(itemData, 'title'),
                  (count) => count === 1,
                )
                const sizeCount = countBy(itemData, 'size')
                const sizesKeys = Object.keys(sizeCount)
                const image = isMultiple
                  ? 'https://cdn-icons-png.freepik.com/512/2742/2742687.png'
                  : !isEmpty(item?.assets)
                  ? item?.assets[0]?.asset?.url
                  : 'https://cdn.vectorstock.com/i/preview-1x/82/99/no-image-available-like-missing-picture-vector-43938299.jpg'

                const displaySizes = sizesKeys.map((size, index) => (
                  <Box
                    height={25}
                    key={index}
                    component="span"
                    px={0.5}
                    mx={0.5}
                    mb={0.5}
                    justifyContent="center"
                    border="1px solid #eee"
                    display="flex"
                    minWidth="95px"
                  >
                    {size} :{' '}
                    <Box component="span" fontWeight={600} ml={0.3}>
                      {sizeCount[size]}
                    </Box>
                  </Box>
                ))

                const sizesText = sizesKeys.map((size) => `${size}: ${sizeCount[size]}`).join('\n')

                const averagePrice = map(itemData || [], (i) => _getValue(i.price))
                const averageListPrice = map(itemData || [], (i) => _getValue(i.desiredReturn))

                const averageDays = chain(itemData)
                  .flatMap((item) => {
                    switch (tabPanel) {
                      case TabPanel.UNLISTED:
                        return moment().diff(moment(item?.acquiredDate), 'days')
                      case TabPanel.LISTED:
                        return item?.listings?.map((listing) =>
                          moment().diff(moment(listing?.createdAt), 'days'),
                        )
                      case TabPanel.SOLD:
                      case TabPanel.PROCESSED:
                        return item?.listings?.flatMap((listing) =>
                          listing?.solds.map((sold) => moment().diff(moment(sold?.createdAt), 'days')),
                        )
                      default:
                        return []
                    }
                  })
                  .thru((diffs) => (diffs.length ? sum(diffs) / diffs.length : 0))
                  .value()

                const hasConsignValue = some(itemData, (item) => isConsigned(item))

                return (
                  <Stack key={index} width={1} direction="row">
                    <Card sx={{flex: 1}}>
                      <Stack direction="column" spacing={1} p={2}>
                        <Stack
                          direction="row"
                          spacing={2}
                          flexWrap="wrap"
                          flex={1}
                          justifyContent="space-between"
                        >
                          <CopytTooltip
                            title={hasConsignValue ? 'Consigned item present in group' : ''}
                            placement="right"
                            backgroundColor="var(--lighter)"
                            color="var(--primary)"
                          >
                            <Box display="flex" width="15%">
                              {hasConsignValue ? (
                                <div className={clx('no-image', 'img-overlay-wrap')}>
                                  <ConsignedBannerSvg />
                                </div>
                              ) : (
                                <Box height="50px" />
                              )}
                              <CardMedia
                                component="img"
                                height="100"
                                sx={{objectFit: 'contain !important'}}
                                image={image}
                                alt={item?.title}
                              />
                            </Box>
                          </CopytTooltip>
                          <Stack spacing={1} width="20%">
                            <CardText fontSize="0.9rem" whiteSpace="unset">
                              <Box
                                component="span"
                                fontWeight={600}
                                sx={{
                                  display: '-webkit-box',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 2,
                                }}
                              >
                                <CopytTooltip
                                  title={
                                    isMultiple
                                      ? uniq(itemData?.map((item) => item?.title)).join('\n')
                                      : ''
                                  }
                                  backgroundColor="var(--lighter)"
                                  color="var(--primary)"
                                  maxWidth={400}
                                  maxHeight={300}
                                >
                                  {isMultiple
                                    ? 'Multiple Items'
                                    : item?.title ?? (isEmpty(item?.item) && itemData[0]?.title) ?? ''}
                                </CopytTooltip>
                              </Box>
                            </CardText>
                            <CardText display="block">
                              <Box component="span" fontWeight={600} marginRight={1}>
                                SKU/Style ID:
                              </Box>
                              <CopytTooltip
                                title={
                                  isMultiple ? uniq(itemData?.map((item) => item?.sku)).join('\n') : ''
                                }
                                backgroundColor="var(--lighter)"
                                color="var(--primary)"
                                placement="left"
                                maxHeight={300}
                              >
                                {isMultiple
                                  ? uniq(itemData?.map((item) => item?.sku)).join(', ')
                                  : itemData[0]?.sku ?? ''}
                              </CopytTooltip>
                            </CardText>
                            {item.hasOwnProperty('condition') && (
                              <CardText>
                                <Box component="span" fontWeight={600}>
                                  Condition:
                                </Box>
                                {item?.condition ?? itemData[0]?.condition ?? ''}
                              </CardText>
                            )}
                            {item.hasOwnProperty('location') && (
                              <CardText>
                                <Box component="span" fontWeight={600}>
                                  Location:
                                </Box>{' '}
                                {item?.location ?? itemData[0]?.location ?? ''}
                              </CardText>
                            )}
                            <CardText>
                              <Box component="span" fontWeight={600}>
                                Qty:
                              </Box>
                              <Chip
                                sx={{
                                  width: 50,
                                  height: 25,
                                  color: 'white',
                                  fontWeight: 600,
                                  backgroundColor: 'var(--gray)',
                                }}
                                label={itemData?.length}
                              />
                            </CardText>
                          </Stack>
                          <Stack
                            direction="column"
                            border="1px solid"
                            width={{xl: '30%', md: '330px'}}
                            justifyContent="space-between"
                          >
                            <CopytTooltip title="Copy" placement="bottom-end">
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                borderBottom="1px solid"
                                backgroundColor="var(--success)"
                                pr={1}
                                height={25}
                                onClick={(event) => {
                                  event.stopPropagation()
                                  copyToClipboard(sizesText)
                                }}
                              >
                                <ContentPasteIcon
                                  color="white"
                                  cursor="pointer"
                                  sx={{height: '0.75em !important'}}
                                />
                                <CopytLabel sx={{color: 'white !important', cursor: 'pointer', mb: 0}}>
                                  Copy Size List
                                </CopytLabel>
                              </Stack>
                            </CopytTooltip>
                            <Box flex={1} mt={0.5}>
                              <Stack
                                direction="row"
                                flexWrap="wrap"
                                justifyContent="flex-start"
                                alignContent="flex-start"
                                height="120px"
                                sx={{overflowY: 'auto'}}
                              >
                                {displaySizes}
                              </Stack>
                            </Box>
                          </Stack>
                          <Stack direction="column" spacing={1} width="20%">
                            <CardText>
                              <Box component="span" fontWeight={600}>
                                Avg. Age:
                              </Box>{' '}
                              {Number.isInteger(_getValue(averageDays))
                                ? _getValue(averageDays)
                                : _getValue(averageDays).toFixed(1)}
                            </CardText>
                            <CardText>
                              <Box component="span" fontWeight={600}>
                                Avg. Buy Price:
                              </Box>{' '}
                              {currency.format(mean(averagePrice) ?? 0)}
                            </CardText>
                            <CardText>
                              <Box component="span" fontWeight={600}>
                                {tabPanel > TabPanel.LISTED ? 'Avg. Sell Price' : 'Avg. List Price'}:
                              </Box>
                              {currency.format(mean(averageListPrice) ?? 0)}
                            </CardText>
                            <CardText>
                              <Box component="span" fontWeight={600}>
                                {tabPanel > TabPanel.LISTED ? 'Total Actual Return' : 'Total List Price'}
                                :
                              </Box>
                              {currency.format(
                                sumBy(itemData, (product) => parseFloat(product?.desiredReturn)),
                              ) ?? 0}
                            </CardText>
                          </Stack>
                          <Box display="flex" alignItems="flex-end">
                            {index === showUnlistedTableIndex ? (
                              <ExpandLessIcon
                                sx={{fontSize: '3rem'}}
                                cursor="pointer"
                                color="primary"
                                onClick={() => handleCardClick(index)}
                              />
                            ) : (
                              <ExpandMoreIcon
                                sx={{fontSize: '3rem'}}
                                cursor="pointer"
                                color="primary"
                                onClick={() => handleCardClick(index)}
                              />
                            )}
                          </Box>
                        </Stack>
                      </Stack>
                      <Box sx={{display: index === showUnlistedTableIndex ? '' : 'none'}}>
                        {TableView(index, itemData)}
                      </Box>
                    </Card>
                  </Stack>
                )
              })}
          </Stack>
        )}
      </DetailView.Panel>
    </React.Fragment>
  )
}

export default InventoryCardList
