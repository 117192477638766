import React from 'react'
import PropTypes from 'prop-types'
import clx from 'classnames'

import CopytModal from 'components/CopytModal'
import {ModalType} from 'enums'
import {
  MdlAddress,
  MdlConsignmentBatchShippingPrompt,
  MdlCreateBox,
  MdlCreateBoxPreset,
  MdlCreateLabel,
  MdlDiscountCode,
  MdlLabelPurchased,
  MdlPackageDetails,
  MdlPaymentMethod,
  MdlPurchaseLabel,
  MdlSelectApprovedItems,
  MdlSoldItems,
  MdlUnlistedItems,
} from './modal-contents'
import MdlShipmentDetails from './modal-contents/MdlShipmentDetails'

const ModalContents = ({children, common, focus, onCloseModal}) => {
  if (focus !== ModalType.UNDEFINED) {
    return React.Children.map(children, (c, i) => {
      if (c.props?.modalType === focus) {
        // Show modal based on the given modalType
        return React.cloneElement(c, {...common, onCloseModal})
      }
      return null
    })
  }
  return null
}

const ShippingModals = (p) => {
  const {modalType, setModalType, modals} = p
  const m = modals[modalType]

  const onClose = () => {
    setModalType(ModalType.UNDEFINED)
  }

  return (
    <CopytModal
      className={m?.className || null}
      closeButton={m?.closeButton}
      id={m?.id}
      show={modalType > ModalType.UNDEFINED}
      size={m?.size || 'sm'}
      toggle={onClose}
    >
      <ModalContents focus={modalType} common={p} onCloseModal={onClose}>
        <MdlAddress modalType={ModalType.ADDRESS} />
        <MdlCreateBox modalType={ModalType.CREATE_BOX} />
        <MdlCreateBoxPreset modalType={ModalType.CREATE_BOX_PRESET} />
        <MdlCreateLabel modalType={ModalType.CREATE_LABEL} />
        <MdlDiscountCode modalType={ModalType.DISCOUNT_CODE} />
        <MdlLabelPurchased modalType={ModalType.LABEL_PURCHASED} />
        <MdlPackageDetails modalType={ModalType.PACKAGE_DETAILS} />
        <MdlPaymentMethod modalType={ModalType.PAYMENT_METHOD} />
        <MdlPurchaseLabel modalType={ModalType.PURCHASE_LABEL} />
        <MdlSelectApprovedItems modalType={ModalType.SELECT_APPROVED_ITEMS} />
        <MdlSoldItems modalType={ModalType.ORDER_FULFILLMENT} />
        <MdlUnlistedItems modalType={ModalType.PURCHASE} />
        <MdlShipmentDetails modalType={ModalType.SHIPMENT_DETAILS} />
        <MdlConsignmentBatchShippingPrompt modalType={ModalType.CONSIGNMENT_BATCH_SHIPPING_PROMPT} />
      </ModalContents>
    </CopytModal>
  )
}

ShippingModals.propTypes = {
  modals: PropTypes.object,
}

ShippingModals.defaultProps = {
  modals: {
    [ModalType.SHIPMENT_DETAILS]: {
      className: clx('no-padding', 'purchase-label'),
      size: 'xl',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.ADDRESS]: {
      className: clx('address', 'no-padding'),
      size: 'xl',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.CREATE_BOX]: {
      className: clx('no-padding'),
    },
    [ModalType.CREATE_BOX_PRESET]: {
      className: clx('no-padding'),
      size: 'xl',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.CREATE_LABEL]: {
      className: clx('no-padding'),
      size: 'xs',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.DISCOUNT_CODE]: {
      className: clx('no-padding'),
      size: 'sm',
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.PACKAGE_DETAILS]: {
      className: clx('no-padding'),
      closeButton: {
        hidden: true,
      },
    },
    [ModalType.SELECT_APPROVED_ITEMS]: {
      className: clx('no-padding'),
      size: 'xl',
    },
    [ModalType.ORDER_FULFILLMENT]: {
      className: clx('no-padding'),
      size: 'xl',
    },
    [ModalType.PURCHASE]: {
      className: clx('no-padding'),
      size: 'xl',
    },
    [ModalType.PURCHASE_LABEL]: {
      className: clx('no-padding', 'purchase-label'),
      closeButton: {
        hidden: true,
      },
      size: 'xl',
    },
    [ModalType.PAYMENT_METHOD]: {
      className: clx('no-padding'),
      closeButton: {
        hidden: true,
      },
      size: 'sm',
    },
    [ModalType.LABEL_PURCHASED]: {
      className: clx('no-padding'),
      size: 'sm',
    },
    [ModalType.CONSIGNMENT_BATCH_SHIPPING_PROMPT]: {
      className: clx('no-padding'),
      closeButton: {
        hidden: true,
      },
    },
  },
}

export default ShippingModals
