import React, {useState} from 'react'
import {isArray, orderBy} from 'lodash'
import {useAuth0} from '@auth0/auth0-react'
import Select from 'react-select'
import {Box, Grid, Stack, IconButton, Typography} from '@mui/material'
import {styled} from '@mui/material/styles'
import {Edit as EditIcon, Delete as DeleteIcon} from '@mui/icons-material'

import CopytCard from 'components/CopytCard'
import {ModalType} from 'enums'
import AddressComponents from 'views_v2/lib/components/AddressComponent'
import {CopytButton, CopytLabel} from 'views_v2/lib/snippets'
import {getStoreLocations} from 'service/hook/use_store_locations'

const StoreText = styled(Typography)((props) => ({
  ...props,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  color: 'var(--gray-dark) !important',
  fontSize: '0.9rem',
}))

const LocationTable = ({
  rows,
  onDelete,
  setLocForUpdate,
  disableModify,
  activeImportStoreLocations,
  onChangeLocation,
  importedStoreLocations,
  fetchingStoreLocations,
}) => {
  if (!rows.length) return 'No Location Available'

  const getPlatformStoreLocations = (platformStoreLocations, row) => {
    const fullAddress = AddressComponents.parse(row).fullAddress()
    if (isArray(platformStoreLocations)) {
      return [
        {label: fullAddress, value: row},
        ...platformStoreLocations
          ?.filter((storeLocation) => {
            return AddressComponents.parse(storeLocation).fullAddress()
          })
          .map((storeLocation) => ({
            label: AddressComponents.parse(storeLocation).fullAddress(),
            value: {...storeLocation, id: row.id},
          })),
      ]
    }
    return [{label: fullAddress, value: fullAddress}]
  }

  return (
    <Box padding="0">
      <Stack direction="column" border="1px solid gray">
        <Stack direction="row" borderBottom="1px solid gray" padding="10px" bgcolor="lightgray">
          <Box width="30%">
            <CopytLabel sx={{fontSize: '0.9rem'}}>Name</CopytLabel>
          </Box>
          <Box width="50%">
            <CopytLabel sx={{fontSize: '0.9rem'}}>Address</CopytLabel>
          </Box>
          <Box width="20%" />
        </Stack>

        <Box maxHeight="30vh" overflow="hidden auto">
          {rows.map((row, idx) => {
            const fullAddress = AddressComponents.parse(row).fullAddress()

            const storeLocations = getPlatformStoreLocations(importedStoreLocations, row)
            const isDefault = row?.isDefault ?? row?.name?.toLowerCase() === 'default'

            return (
              <Stack
                direction="row"
                spacing={1}
                borderBottom="1px solid var(--lighter)"
                alignItems="center"
                paddingX={2}
                key={idx}
              >
                <Box width="30%">
                  <StoreText>{row.name}</StoreText>
                </Box>
                <Box width="50%">
                  {activeImportStoreLocations.shopify ? (
                    <Box padding="10px">
                      {!fetchingStoreLocations ? (
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          defaultValue={storeLocations[0]}
                          placeholder="Choose Address"
                          closeMenuOnSelect
                          isClearable
                          loading={fetchingStoreLocations}
                          onChange={(store) => {
                            if (store) {
                              setLocForUpdate(store.value)
                              onChangeLocation(store.value, idx)
                            }
                          }}
                          options={storeLocations}
                          menuPortalTarget={document.body}
                          styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                        />
                      ) : (
                        'Loading Store Locations'
                      )}
                    </Box>
                  ) : (
                    <StoreText>{fullAddress}</StoreText>
                  )}
                </Box>
                <Box width="20%">
                  <Stack direction="row" alignItems="center" justifyContent="flex-end">
                    {isDefault ? (
                      <Box
                        borderRadius="8px"
                        height="20px"
                        fontSize="12px"
                        bgcolor="#D9D9D9"
                        padding="10px"
                        display="flex"
                        alignItems="center"
                      >
                        Default
                      </Box>
                    ) : (
                      <IconButton
                        onClick={() => onDelete(row)}
                        disabled={disableModify}
                        sx={{opacity: rows.length > 1 && !isDefault ? 1 : 0}}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() => setLocForUpdate(row)}
                      disabled={disableModify || activeImportStoreLocations.shopify}
                    >
                      <EditIcon />
                    </IconButton>
                  </Stack>
                </Box>
              </Stack>
            )
          })}
        </Box>
      </Stack>
    </Box>
  )
}

const StoreLocations = (p) => {
  const {setModalType, locations, onDeleteLocation, setLocationToUpdate, loading} = p
  const {getAccessTokenSilently} = useAuth0()

  const [importedStoreLocations, setImportedStoreLocations] = useState([])

  const [currentStoreLocations, setCurrentStoreLocations] = useState(locations)
  const [fetchingStoreLocations, setStoreLocations] = useState(false)
  const [activeImportStoreLocations, setActiveImportStoreLocations] = useState({
    shopify: false,
  })

  const toggleImportStoreLocations = async (platform) => {
    setActiveImportStoreLocations((prevState) => ({...prevState, [platform]: !prevState[platform]}))
    setStoreLocations(true)
    const updatedStoreLocations = await getStoreLocations(getAccessTokenSilently)
    setStoreLocations(false)
    setImportedStoreLocations(updatedStoreLocations)
  }

  const onChangeLocation = (storeLocation, idx) => {
    const updatedStoreLocations = currentStoreLocations.map((loc, i) => {
      if (i === idx) return storeLocation
      return loc
    })
    setCurrentStoreLocations(updatedStoreLocations)
  }

  const onDelete = (location) => {
    onDeleteLocation(location?.id)
  }

  const setLocForUpdate = (location) => {
    setLocationToUpdate(location)
    setModalType(ModalType.UPDATE_STORE_LOCATION)
  }

  return (
    <CopytCard className="copyt-card store-locations">
      <CopytCard.Header>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <CopytCard.Text tag="div">Store Locations</CopytCard.Text>
          </Grid>
          <Grid item>
            <Box display="flex" justifyContent="flex-end" gap={2}>
              {!activeImportStoreLocations.shopify ? (
                <Grid item>
                  <CopytButton
                    onClick={() => toggleImportStoreLocations('shopify')}
                    variant="outline"
                    color="primary"
                  >
                    Import Shopify Locations
                  </CopytButton>
                </Grid>
              ) : null}
              <Grid item>
                <CopytButton
                  onClick={() => setModalType(ModalType.ADD_STORE_LOCATIONS)}
                  variant="outline"
                  color="primary"
                >
                  Add New
                </CopytButton>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CopytCard.Header>
      <CopytCard.Body>
        <div className="sub">
          {activeImportStoreLocations.shopify
            ? 'If you manage multiple Shopify locations, you can link your Copyt Store Locations to your Shopify Locations here. Import your Shopify Locations above and then select which Shopify Location to map each Copyt listing to.'
            : 'In order to ensure the best possible inventory sync, type your Store Location exactly as it appears in your POS and Website platforms.'}
        </div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '200px',
            gap: 2,
            marginTop: 2,
          }}
        >
          <LocationTable
            rows={orderBy(locations, ['isDefault', 'name'], ['desc', 'asc'])}
            onDelete={onDelete}
            setLocForUpdate={setLocForUpdate}
            disableModify={loading}
            activeImportStoreLocations={activeImportStoreLocations}
            fetchingStoreLocations={fetchingStoreLocations}
            onChangeLocation={onChangeLocation}
            importedStoreLocations={importedStoreLocations}
          />
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography fontSize="0.9rem">
              Total Number of Locations:&nbsp;
              <Box component="span" fontWeight={600}>
                {locations?.length || 0}
              </Box>
            </Typography>
            {activeImportStoreLocations.shopify ? (
              <CopytButton
                onClick={() => toggleImportStoreLocations('shopify')}
                variant="outline"
                color="primary"
              >
                Close
              </CopytButton>
            ) : null}
          </Stack>
        </Box>
      </CopytCard.Body>
    </CopytCard>
  )
}

export default StoreLocations
