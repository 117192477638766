import React from 'react'
import {Box, FormControlLabel, Grid, TextField, Typography} from '@mui/material'
import {CopytLabel, FieldGroup} from 'views_v2/lib/snippets'
import CheckSwitch from 'views_v2/lib/snippets/CopytSwitch/CheckSwitch'
import Select from 'react-select'
import {onKeyPress} from 'views_v2/lib/common'
import {useSiteSettings} from 'service/hook'
import {color_options, flaw_options, tag_options} from 'assets/data'
import {options, accessories_options, apparel_options} from 'assets/data/options/sub_category_options'
import {_replaceWildCards} from 'util/string_utils'

const MdlAdditionalFields = ({
  formFields,
  productTemplate,
  previewState,
  handleInputChange,
  readOnly = false,
  setFormFields,
  setPreviewState,
  setProductTemplate,
  template,
  updateDescriptionTemplate,
}) => {
  const {currentDescriptionWildcardList} = useSiteSettings()

  let subCategory = options
  if (formFields?.category?.toLowerCase() === 'apparel') {
    subCategory = apparel_options
  } else if (formFields?.category?.toLowerCase() === 'accessories') {
    subCategory = accessories_options
  }

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading">
          <Typography variant="h1">Additional Fields</Typography>
        </Box>
      </Box>
      <Box className="modal-body" style={{padding: '15px 0'}}>
        <CopytLabel className="half-width">Use Product Template?</CopytLabel>
        <Select
          className="react-select info"
          classNamePrefix="react-select"
          isDisabled={template?.length === 0}
          onChange={(e) => {
            setProductTemplate(e)
            let category = `Men's Shoes`
            let condition = 'Brand New'
            let description = 'SKU: [_]\n'
            let boxWeight = '4 lb'

            if (template?.length > 0 && e?.value) {
              const tmp = template[0]
              category = tmp?.category
              condition = tmp?.condition
              description = updateDescriptionTemplate('condition', tmp?.description)
              boxWeight = `${tmp?.shippingWieght} lb`
            }

            setFormFields({
              ...formFields,
              category,
              condition,
              description,
              boxWeight,
            })
          }}
          options={[
            {value: false, label: 'No'},
            {value: true, label: `Yes - ${template[0]?.name}`},
          ]}
          value={productTemplate}
          tabIndex="12"
        />

        <Grid container gap={1}>
          <Grid item xs={12} sm={5.8}>
            <CopytLabel marginTop={1}>Subcategory</CopytLabel>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="Choose Subcategory"
              closeMenuOnSelect
              isClearable
              onChange={(e) => {
                setFormFields({
                  ...formFields,
                  subcategory: e?.value || '',
                })
              }}
              options={subCategory}
              value={
                (formFields.subcategory && {
                  value: formFields.subcategory,
                  label: formFields.subcategory,
                }) ||
                ''
              }
              tabIndex="13"
            />
          </Grid>
          <Grid item xs={12} sm={5.8}>
            <CopytLabel marginTop={1}>Color</CopytLabel>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="Choose Color"
              closeMenuOnSelect
              isClearable
              onChange={(e) =>
                setFormFields({
                  ...formFields,
                  color: e?.value || '',
                })
              }
              options={color_options}
              value={
                (formFields.color && {
                  value: formFields.color,
                  label: formFields.color,
                }) ||
                ''
              }
              tabIndex="14"
            />
          </Grid>
          <Grid item xs={12} sm={5.8}>
            <CopytLabel marginTop={1}>Tag status</CopytLabel>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="Choose Tag Status"
              closeMenuOnSelect
              onChange={(e) =>
                setFormFields({
                  ...formFields,
                  tagStatus: e.value,
                })
              }
              options={tag_options}
              value={
                (formFields.tagStatus && {
                  value: formFields.tagStatus,
                  label: formFields.tagStatus,
                }) || {value: 'Intact', label: 'Intact'}
              }
              tabIndex="17"
            />
          </Grid>
          <Grid item xs={12} sm={5.8}>
            <CopytLabel marginTop={1}>Flaws</CopytLabel>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              placeholder="Choose Flaws"
              closeMenuOnSelect
              isClearable
              onChange={(e) =>
                setFormFields({
                  ...formFields,
                  flaws: e?.value || '',
                })
              }
              options={flaw_options}
              value={
                (formFields.flaws && {
                  value: formFields.flaws,
                  label: formFields.flaws,
                }) ||
                ''
              }
              tabIndex="19"
            />
          </Grid>
          <Grid item xs={12} sm={5.8}>
            <FieldGroup
              {...{readOnly}}
              sLabel="Release Date"
              name="releaseDate"
              type={readOnly ? 'text' : 'date'}
              value={formFields.releaseDate || ''}
              tabIndex="21"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={5.8}>
            <FieldGroup
              {...{readOnly}}
              sLabel="Retail Price"
              name="retailPrice"
              value={formFields.retailPrice || ''}
              onChange={handleInputChange}
              placeholder="Enter retail price"
              onKeyPress={(e) => onKeyPress(e, 'num')}
              maxLength={6}
              required
            />
          </Grid>
        </Grid>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <CopytLabel>Description</CopytLabel>
          <FormControlLabel
            label="Preview"
            sx={{
              m: 1,
              color: '#5D0953',
            }}
            componentsProps={{
              typography: {
                fontSize: '12px',
              },
            }}
            control={<CheckSwitch sx={{m: 0}} checked={previewState} disabled={readOnly} />}
            onChange={() => setPreviewState(!previewState)}
          />
        </Box>
        <Box>
          <TextField
            id="outlined-multiline-flexible"
            multiline
            fullWidth
            disabled={previewState || readOnly}
            minRows={5}
            maxRows={8}
            onChange={(e) =>
              setFormFields({
                ...formFields,
                description: e.target.value,
              })
            }
            value={
              previewState
                ? _replaceWildCards(formFields, formFields.description, currentDescriptionWildcardList)
                : formFields.description
            }
          />
        </Box>
      </Box>
    </Box>
  )
}

export default MdlAdditionalFields
