import {ListingPlatformStatus} from 'constant/listing_platform_status'
import {ListingStatus} from 'constant/listing_status'
import {get, isEmpty} from 'lodash'
import {TabPanel} from '..'

export function getListingStatus(product) {
  return get(product, 'listings[0]?.status', 'shouldnotnull')
}

export function getListingPlatformStatus(product) {
  return get(product, 'listings[0]?.listingPlatforms[0]?.status', 'shouldnotnull')
}

function getListingPlatforms(product) {
  return get(product, 'listings[0]?.listingPlatforms', [])
}

function getFindListingStatus(product, statuses = []) {
  const lp = getListingPlatforms(product) || []
  return lp.find((i) => statuses.includes(i.status))
}

export const isListingWaiting = (product) => {
  return getListingStatus(product) === ListingStatus.CREATED
}

export const isListingSuccess = (product) => {
  return !!getFindListingStatus(product, [ListingStatus.LISTED])
}

export const isListingPending = (product) => {
  return product?.listings[0]?.listingPlatforms.find(
    (lp) => lp?.status === ListingPlatformStatus.PENDING,
  )
}

export const isListingFailure = (product) => {
  const lp = product.listings[0]?.listingPlatforms
  const FailedLisitngPlatforms = lp.filter((i) => i?.status === ListingPlatformStatus.LISTING_FAILED)
  return FailedLisitngPlatforms
}

export const getListingStatusProps = (status) => {
  switch (status) {
    case ListingPlatformStatus.LISTED:
      return {color: 'green', text: 'Listed'}
    case ListingPlatformStatus.PENDING:
      return {color: 'gray', text: 'Queued'}
    case ListingPlatformStatus.LISTING_FAILED:
      return {color: 'red', text: 'Failed'}
    case ListingPlatformStatus.HOLD_LISTED:
      return {color: '#0072b0', text: 'POS Listed'}
    case ListingPlatformStatus.RELISTING:
      return {color: '#bfbf00', text: 'Relisting'}
    case ListingPlatformStatus.PROCESSING:
      return {color: 'purple', text: 'Processing'}
    case ListingPlatformStatus.REMOVE_FAILED:
      return {color: '#d47400', text: 'Delisting Failed'}
    default:
      return ''
  }
}

/**
 *
 * @param {*} items[]
 * @param {0 | 1 | 2} tableTab
 */
export function generateExportData(items, tableTab = 0) {
  if (!items) return []
  switch (tableTab) {
    case TabPanel.UNLISTED:
      return [].concat(items).map((data) => {
        const ownerDetails = get(data, 'owner', {email: '', firstName: '', lastName: ''})
        const consign = get(data, 'consign', {
          status: '',
          createdAt: '',
          updatedAt: '',
        })

        let exportData = {
          name: get(data, 'title', ''),
          sku: get(data, 'sku', ''),
          size: get(data, 'size', ''),
          condition: get(data, 'condition', ''),
          buyPrice: get(data, 'price', ''),
          listPrice: get(data, 'desiredReturn', ''),
          internalSku: get(data, 'internalSku', ''),
          location: get(data, 'location', ''),
          subLocation: get(data, 'subLocation', ''),
          acquiredDate: get(data, 'acquiredDate', ''),
          status: get(data, 'status', 'unlisted'),
          owner: `${ownerDetails?.firstName || ''} ${ownerDetails?.lastName || ''}`,
          owner_email: ownerDetails?.email || '',
          feeStructure: get(data, 'feeStructure', 0),
        }

        if (get(consign, 'status', '') === 'approved' && consign?.application) {
          exportData = {...exportData, approvalDate: get(consign, 'application.acquiredDate', null)}
        }
        return exportData
      })
    case TabPanel.LISTED:
      return [].concat(items).map((data) => {
        const ownerDetails = get(data, 'owner', {email: '', firstName: '', lastName: ''})
        const consign = get(data, 'consign', {
          status: '',
          createdAt: '',
          updatedAt: '',
        })

        let exportData = {
          name: get(data, 'title', ''),
          sku: get(data, 'sku', ''),
          size: get(data, 'size', ''),
          condition: get(data, 'condition', ''),
          buyPrice: get(data, 'price', ''),
          listPrice: get(data, 'desiredReturn', ''),
          internalSku: get(data, 'internalSku', ''),
          location: get(data, 'location', ''),
          subLocation: get(data, 'subLocation', ''),
          acquiredDate: get(data, 'acquiredDate', ''),
          owner: `${ownerDetails?.firstName || ''} ${ownerDetails?.lastName || ''}`,
          owner_email: ownerDetails?.email || '',
          status: get(data, 'status', 'listed'),
        }

        if (get(consign, 'status', '') === 'approved' && consign?.application) {
          exportData = {...exportData, approvalDate: get(consign, 'application.acquiredDate', null)}
        }
        return exportData
      })
    case TabPanel.SOLD:
    case TabPanel.PROCESSED:
      return [].concat(items).map((data) => {
        const ownerDetails = get(data, 'owner', {email: '', firstName: '', lastName: ''})
        const listings = get(data, 'listings', [])
        const soldListings = listings.length > 0 && listings[0]?.solds
        const consign = get(data, 'consign', {
          status: '',
          createdAt: '',
          updatedAt: '',
        })

        let exportData = {
          name: get(data, 'title', ''),
          sku: get(data, 'sku', ''),
          size: get(data, 'size', ''),
          condition: get(data, 'condition', ''),
          buyPrice: get(data, 'price', ''),
          listPrice: get(data, 'desiredReturn', ''),
          internalSku: get(data, 'internalSku', ''),
          location: get(data, 'location', ''),
          subLocation: get(data, 'subLocation', ''),
          acquiredDate: get(data, 'acquiredDate', ''),
          owner: `${ownerDetails?.firstName || ''} ${ownerDetails?.lastName || ''}`,
          owner_email: ownerDetails?.email || '',
          status: get(data, 'status', `${TabPanel.SOLD === tableTab ? 'sold' : 'processed'}`),
          orderId: get(soldListings[0], 'id', ''),
          soldDate: get(soldListings[0], 'createdAt', ''),
        }

        if (get(consign, 'status', '') === 'approved' && consign?.application) {
          exportData = {...exportData, approvalDate: get(consign, 'application.acquiredDate', null)}
        }
        return exportData
      })
    default:
      return []
  }
}
