import formatAddress from './formatAddress'

export const createAddressSuggestions = (shipments = []) => {
  return shipments.map((shipment) => {
    return {
      id: shipment?.id || shipment?.object_id,
      text: `${shipment?.name}: ${formatAddress(shipment)}`,
    }
  })
}

export default createAddressSuggestions
