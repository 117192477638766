import React from 'react'
import {Box, Typography, Stack} from '@mui/material'

import copytLogo from 'assets/img/copyt-logo-sidebar.svg'
import {ModalType} from 'enums'
import {_replaceWildCards, _getValue} from 'util/string_utils'
import {getCurrency} from 'util/model/setting'
import {CopytStyledButton} from 'views_v2/lib/snippets'

const MdlEcgItemSubmission = (p) => {
  const {setModalType, payload, handleConsignmentBatchPayment} = p
  const currency = getCurrency()

  const {count} = payload?.paymentData || {}

  return (
    <Box className="bulk-edit">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography
            variant="h1"
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
          >
            <Stack direction="row">
              <Typography color="white !important">{count} items included</Typography>
            </Stack>
            Item Submission Fees
          </Typography>
        </Box>
      </Box>
      <Box className="modal-body" maxHeight="85vh" padding="0" mb={2}>
        <Stack direction="column" padding={1} alignItems="center">
          <Box mx={3} mb={3}>
            In order to print your batch summary, you must first pay your item submission fees associated
            with this batch.
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="60%"
            borderRadius={2}
            pl={1}
            pr={2}
            border="1px solid var(--gray)"
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                className="barcode-image"
                component="img"
                sx={{
                  maxWidth: '85%',
                  maxHeight: '85%',
                  width: 'auto',
                  height: 'auto',
                }}
                src={copytLogo}
              />
              <Typography>{`${count} items x ${currency.format('3')}`}</Typography>
            </Stack>
            <Typography>{currency.format(count * 3)}</Typography>
          </Stack>
          <Box mt={3} mx={3}>
            By clicking ‘Pay Now’, the payment method on your account will automatically be charged for
            the total amount.
          </Box>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={1.5}
          mt={4}
          borderTop="1px solid var(--lighter)"
          spacing={2}
        >
          <CopytStyledButton
            variant="outline"
            color="primary"
            onClick={() => setModalType(ModalType.UNDEFINED)}
          >
            Cancel
          </CopytStyledButton>
          <CopytStyledButton
            variant="contained"
            color="primary"
            onClick={async () => {
              await handleConsignmentBatchPayment()
            }}
          >
            Pay Now
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlEcgItemSubmission
