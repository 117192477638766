import moment from 'moment'

export const getTime = (ms) => {
  const min = Math.floor(ms / 60000)
  const hour = Math.floor(ms / 3600000)
  const day = Math.floor(ms / 86400000)

  if (day >= 1) {
    return `${day} day${day > 1 ? 's' : ''}`
  } else if (hour >= 1) {
    return `${hour} hour${hour > 1 ? 's' : ''}`
  } else {
    return `${min} min${min > 1 ? 's' : ''}`
  }
}

export const listingDuration = (time) => (time ? getTime(moment().diff(time)) : '')

export const getDuration = (listtime, soldtime) => {
  return listtime && soldtime ? getTime(moment(soldtime).diff(listtime)) : ''
}

export default getDuration
