import {useState, useEffect} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import axios from 'axios'
import {useAuth0} from '@auth0/auth0-react'
import Qs from 'querystring'
import {captureException} from '@sentry/react'

import {paths} from 'constant/api'
import {useToastMessage} from 'components/index.js'
import {createRequestOptions} from 'util/network'
import {ar} from 'variables/empty'

const getConsignments = async (getAccessTokenSilently, filter, searchText) => {
  try {
    const token = await getAccessTokenSilently()
    const response = await axios.get(
      `${paths.consign}?${Qs.stringify({...filter, search: searchText})}`,
      createRequestOptions(token),
    )

    return response.data
  } catch (error) {
    captureException(error)
    console.log('Error retrieving invoices', error)
    throw error
  }
}

const getApprovedPlatforms = async (getAccessTokenSilently, filter) => {
  try {
    const token = await getAccessTokenSilently()
    const res = await axios.get(
      `${paths.consign}/platform-approved?${Qs.stringify({...filter})}`,
      createRequestOptions(token),
    )
    return res.data?.data
  } catch (error) {
    captureException(error)
    throw error
  }
}

const getConsignShops = async (getAccessTokenSilently, filter) => {
  try {
    const token = await getAccessTokenSilently()
    const res = await axios.get(
      `${paths.consign}/shops?${Qs.stringify({...filter})}`,
      createRequestOptions(token),
    )
    return res.data
  } catch (error) {
    captureException(error)
    throw error
  }
}

const getConsignmentBatch = async (getAccessTokenSilently, consigneeId) => {
  if (consigneeId) {
    try {
      const token = await getAccessTokenSilently()
      const res = await axios.get(paths.consignmentBatch, createRequestOptions(token))
      return res.data?.data || []
    } catch (error) {
      captureException(error)
      throw error
    }
  }
}

const getItemsApproved = async (getAccessTokenSilently, consigneeId) => {
  if (consigneeId) {
    try {
      const token = await getAccessTokenSilently()
      const res = await axios.get(
        `${paths.consign}/items-approved?consigneeId=${consigneeId}`,
        createRequestOptions(token),
      )
      return res.data?.data
    } catch (error) {
      captureException(error)
      throw error
    }
  }
}

export const useConsignments = (filter = {}, searchText, consigneeId) => {
  const {getAccessTokenSilently} = useAuth0()
  const {showToast} = useToastMessage()
  const queryClient = useQueryClient()
  const [fetchConsignmentsEnabled, setFetchConsignmentsEnabled] = useState(true)
  const [fetchApprovedPlatformsEnabled, setFetchApprovedPlatformsEnabled] = useState(true)
  const [fetchConsignShopsEnabled, setFetchConsignShopsEnabled] = useState(true)
  const [fetchConsignmentBatchEnabled, setFetchConsignmentBatchEnabled] = useState(true)
  const [fetchItemsApprovedEnabled, setFetchItemsApprovedEnabled] = useState(true)

  const {
    isFetching,
    isLoading,
    isError,
    isFetched,
    refetch: refetchConsignments,
    data: consignments = ar,
    error,
  } = useQuery(
    ['consignments', filter, searchText],
    () => getConsignments(getAccessTokenSilently, filter, searchText),
    {
      keepPreviousData: true,
      enabled: fetchConsignmentsEnabled,
      staleTime: Infinity,
    },
  )

  const {data: approvedPlatforms = [], refetch: refetchApprovedPlatforms} = useQuery(
    ['approvedPlatforms', filter],
    () => getApprovedPlatforms(getAccessTokenSilently, filter),
    {
      keepPreviousData: true,
      enabled: fetchApprovedPlatformsEnabled,
      staleTime: Infinity,
    },
  )

  const {data: consignShops = [], refetch: refetchConsignShops} = useQuery(
    ['consignShops', filter],
    () => getConsignShops(getAccessTokenSilently, filter),
    {
      keepPreviousData: true,
      enabled: fetchConsignShopsEnabled,
      staleTime: Infinity,
    },
  )

  const {
    isFetching: isFetchingConsignmentBatch,
    isLoading: isLoadingConsignmentBatch,
    data: consignmentBatch = [],
    refetch: refetchConsignmentBatch,
  } = useQuery(['consignmentBatch', filter], () => getConsignmentBatch(getAccessTokenSilently, filter), {
    keepPreviousData: true,
    enabled: fetchConsignmentBatchEnabled,
    staleTime: Infinity,
  })

  const {
    isFetching: isFetchingItemsApproved,
    data: itemsApproved = [],
    refetch: refetchItemsApproved,
  } = useQuery(
    ['itemsApproved', consigneeId],
    () => getItemsApproved(getAccessTokenSilently, consigneeId),
    {
      keepPreviousData: true,
      enabled: fetchItemsApprovedEnabled,
      staleTime: Infinity,
    },
  )

  let _action = ''

  const onWithdrawItems = useMutation(
    async ({consignIds, action = ''}) => {
      _action = action
      const token = await getAccessTokenSilently()
      return await axios.post(
        `${paths.consign}/withdraw/${action}`,
        {consignIds},
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (d) => {
        const msg =
          `${_action}`.toLowerCase() !== 'approve'
            ? 'Your request was successfully submitted. Track your withdrawn items on your "Consignments page."'
            : 'Item Successfully Withdrawn'
        showToast({variant: 'success', title: 'Success', body: msg})
        setFetchConsignmentsEnabled(true)
        refetchConsignments()
        refetchConsignmentBatch()
        queryClient.invalidateQueries('inventory')
      },
      onError: (e) => {
        showToast({variant: 'danger', title: 'Failed', body: 'Failure to process request!'})
        setFetchConsignmentsEnabled(true)
        refetchConsignments()
        refetchConsignmentBatch()
        queryClient.invalidateQueries('inventory')
      },
    },
  )

  const approveConsignment = useMutation(
    async (id) => {
      const token = await getAccessTokenSilently()
      return await axios.post(
        `${paths.consign}/approve`,
        {
          id,
        },
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (response) => {
        showToast({variant: 'success', title: 'Success', body: 'Item has been approved.'})
        setFetchConsignmentsEnabled(true)
        refetchConsignments()
      },
    },
    {
      onMutate: async () => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
    },
    {
      onError: async (error) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchConsignmentsEnabled(true)
        refetchConsignments()
      },
    },
  )

  const postListAll = useMutation(
    async (body) => {
      const token = await getAccessTokenSilently()
      return await axios.post(`${paths.listing}/list-all`, body, createRequestOptions(token))
    },
    {
      onSuccess: async (response) => {
        showToast({variant: 'success', title: 'Success', body: 'List has been created.'})
        setFetchConsignmentBatchEnabled(true)
        refetchConsignmentBatch()
      },
    },
    {
      onMutate: async () => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
    },
    {
      onError: async (error) => {
        showToast({variant: 'danger', title: 'Error', body: 'Failed to Submit'})
        setFetchConsignmentBatchEnabled(true)
        refetchConsignmentBatch()
      },
    },
  )

  const postList = useMutation(
    async (data) => {
      const token = await getAccessTokenSilently()
      return await axios.post(`${paths.listing}`, data, createRequestOptions(token))
    },
    {
      onSuccess: async (response) => {
        showToast({variant: 'success', title: 'Success', body: 'List has been created.'})
        setFetchConsignmentBatchEnabled(true)
        refetchConsignmentBatch()
      },
    },
    {
      onMutate: async () => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
    },
    {
      onError: async (error) => {
        showToast({variant: 'danger', title: 'Error', body: 'Failed to Submit'})
        setFetchConsignmentBatchEnabled(true)
        refetchConsignmentBatch()
      },
    },
  )

  const rejectConsignment = useMutation(
    async (id) => {
      const token = await getAccessTokenSilently()
      try {
        return await axios.post(
          `${paths.consign}/reject`,
          {
            id,
          },
          createRequestOptions(token),
        )
      } catch (error) {
        console.error('error', error)
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchConsignmentsEnabled(true)
        refetchConsignments()
      }
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Success', body: 'Item has been denied.'})
        setFetchConsignmentsEnabled(true)
        refetchConsignments()
      },
    },
    {
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
    },
    {
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchConsignmentsEnabled(true)
        refetchConsignments()
      },
    },
  )

  const returnConsignment = useMutation(
    async (id) => {
      const token = await getAccessTokenSilently()

      try {
        return await axios.post(
          `${paths.consign}/returned`,
          {
            id,
          },
          createRequestOptions(token),
        )
      } catch (error) {
        console.error('error', error)
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchConsignmentsEnabled(true)
        refetchConsignments()
      }
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Success', body: 'Item has been returned.'})
        setFetchConsignmentsEnabled(true)
        refetchConsignments()
      },
    },
    {
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
    },
    {
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchConsignmentsEnabled(true)
        refetchConsignments()
      },
    },
  )

  const deleteConsignment = useMutation(
    async (id) => {
      const token = await getAccessTokenSilently()

      try {
        return await axios.delete(`${paths.consign}/${id}`, createRequestOptions(token))
      } catch (error) {
        console.error('error', error)
        showToast({variant: 'danger', title: 'Error', body: ''})
        setFetchConsignmentsEnabled(true)
        refetchConsignments()
      }
    },
    {
      onSuccess: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Success', body: 'Item has been removed.'})
        setFetchConsignmentsEnabled(true)
        refetchConsignments()
      },
    },
    {
      onMutate: async (data, variables, context) => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
    },
    {
      onError: async (data, variables, context) => {
        showToast({variant: 'danger', title: 'Error', body: ''})
      },
    },
  )

  const onCreateConsignBatch = useMutation(
    async (data) => {
      const token = await getAccessTokenSilently()
      return await axios.post(
        paths.consignmentBatch,
        {
          ...data,
        },
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (response) => {
        showToast({variant: 'success', title: 'Success', body: 'Consign batch created.'})
        setFetchItemsApprovedEnabled(true)
        refetchItemsApproved()
        setFetchConsignmentBatchEnabled(true)
        refetchConsignmentBatch()
      },
    },
    {
      onMutate: async () => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
    },
    {
      onError: async (error) => {
        showToast({
          variant: 'danger',
          title: 'Error',
          body: error?.response?.data?.msg || 'Failure to process request!',
        })
        setFetchItemsApprovedEnabled(true)
        refetchItemsApproved()
      },
    },
  )

  const onCreateConsignBatchPayment = useMutation(
    async (data) => {
      const token = await getAccessTokenSilently()
      return await axios.post(
        `${paths.consignmentBatch}/payment`,
        {
          ...data,
        },
        createRequestOptions(token),
      )
    },
    {
      onSuccess: async (response) => {
        refetchConsignmentBatch()
        showToast({variant: 'success', title: 'Success', body: 'Payment is processed'})
        setFetchItemsApprovedEnabled(true)
        refetchItemsApproved()
      },
    },
    {
      onMutate: async () => {
        showToast({variant: 'success', title: 'Loading', body: ''})
      },
    },
    {
      onError: async (error) => {
        showToast({
          variant: 'danger',
          title: 'Error',
          body: error?.response?.data?.msg || 'Failure to process request!',
        })
        setFetchItemsApprovedEnabled(true)
        refetchItemsApproved()
      },
    },
  )

  // useEffect(() => {
  //   if (fetchConsignmentsEnabled) {
  //     refetchConsignments()
  //     setFetchConsignmentsEnabled(false)
  //   }
  // }, [fetchConsignmentsEnabled, refetchConsignments])

  // useEffect(() => {
  //   if (!fetchConsignmentsEnabled) {
  //     refetchConsignments()
  //     setFetchConsignmentsEnabled(true)
  //   }
  // }, [searchText, filter])

  useEffect(() => {
    if (fetchApprovedPlatformsEnabled) {
      refetchApprovedPlatforms()
      setFetchApprovedPlatformsEnabled(false)
    }
  }, [fetchApprovedPlatformsEnabled, refetchApprovedPlatforms])

  useEffect(() => {
    if (fetchConsignShopsEnabled) {
      refetchConsignShops()
      setFetchConsignShopsEnabled(false)
    }
  }, [fetchConsignShopsEnabled, refetchConsignShops])

  useEffect(() => {
    if (fetchConsignmentBatchEnabled) {
      refetchConsignmentBatch()
      setFetchConsignmentBatchEnabled(false)
    }
  }, [fetchConsignmentBatchEnabled, refetchConsignmentBatch])

  useEffect(() => {
    if (fetchItemsApprovedEnabled) {
      refetchItemsApproved()
      setFetchItemsApprovedEnabled(false)
    }
  }, [fetchItemsApprovedEnabled, refetchItemsApproved])

  return {
    isFetching,
    isLoading,
    isError,
    isConsignmentsFetched: isFetched,
    refetchConsignments: () => setFetchConsignmentsEnabled(true),
    error,
    refetchConsignmentBatch,
    consignments: consignments || [],
    approvedPlatforms: approvedPlatforms || [],
    itemsApproved: itemsApproved || [],
    consignmentBatch: consignmentBatch,
    consignShops: consignShops || [],
    isFetchingConsignmentBatch,
    isLoadingConsignmentBatch,
    refetchItemsApproved: () => setFetchItemsApprovedEnabled(true),
    isFetchingItemsApproved: isFetchingItemsApproved,
    postListAll: postListAll.mutate,
    postList: postList.mutate,
    postListAllAsync: postListAll.mutateAsync,
    postListAsync: postList.mutateAsync,
    approveConsignment: approveConsignment.mutate,
    rejectConsignment: rejectConsignment.mutate,
    returnConsignment: returnConsignment.mutate,
    deleteConsignment: deleteConsignment.mutate,
    onWithdrawItems: onWithdrawItems.mutate,
    onCreateConsignBatch: onCreateConsignBatch.mutate,
    onCreateConsignBatchAsync: onCreateConsignBatch.mutateAsync,
    onCreateConsignBatchLoading: onCreateConsignBatch.isLoading,
    onCreateConsignBatchPayment: onCreateConsignBatchPayment.mutateAsync,
    isApprovedConsignmentSuccess: approveConsignment.isSuccess,
    isRejectConsignmentSuccess: rejectConsignment.isSuccess,
    isReturnConsignmentSuccess: returnConsignment.isSuccess,
  }
}

export default useConsignments
