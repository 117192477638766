import React, {useEffect, useState, useRef, useReducer} from 'react'
import {useHistory} from 'react-router-dom'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  CircularProgress,
  Stack,
  Box,
  LinearProgress,
} from '@mui/material'
import {styled} from '@mui/material/styles'
import SearchBar from 'material-ui-search-bar'
import {first, includes, isEmpty, pullAt} from 'lodash'
import {useStripe, useElements, CardNumberElement} from '@stripe/react-stripe-js'

import {ModalType} from 'enums'
import {ShippingMenu} from 'assets/data/options/inventory_filter_menu'
import DetailView from 'components/DetailView'
import {useShipments, useDiscounts} from 'service/hook'
import {CopytButton} from 'views_v2/lib/snippets'
import {useGlobalStore} from 'provider/global_store/hook'
import {formatName} from 'util/string_utils'
import mw from './middleware'
import ShippingModals from './ShippingModals'
import ShippingTableRow from './ShippingTableRow'
import FilterToolbar from './FilterToolbar'

export const StyledSearchBar = styled(SearchBar)`
  max-width: 300px;
  height: 40px !important;
`

const defaultParcelFields = {
  weight: null,
  length: null,
  width: null,
  height: null,
  distance_unit: 'in',
  mass_unit: 'lb',
}

const defaultAddressesFields = {
  recipient: {},
  sender: {},
}

const ShippingPrompt = ({setModalType, modalType, consignmentBatchItems, onCreate}) => {
  const {user, hasMop, userRefech} = useGlobalStore()
  const stripe = useStripe()
  const elements = useElements()
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const [shipmentType, setShipmentType] = useState('order-fulfillment')
  const [selectedLocation, setSelectedLocation] = useState()
  const [payload, setPayload] = useState({items: [], boxItems: []})
  const [consignee, setConsignee] = useState({value: null, label: null, location: 'default'})
  const [queries, setQueries] = useState({})
  const [sIds, setSIds] = useState([])
  const [transactions, setTransactions] = useState([])
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const [filter, setFilter] = useState({
    skip: 0,
    take: 100,
    page: 0,
  })
  const [parcel, setParcel] = useState(defaultParcelFields)
  const [recipientId, setRecipientId] = useState()

  const [addresses, setAddresses] = useState(defaultAddressesFields)

  const [billingDetails, setBillingDetails] = useState({
    name: null,
    address: null,
    city: null,
    state: null,
    zip: null,
  })

  const {
    isLoading,
    isFetching,
    isLoadingGetShipmentById,
    shipments,
    setShipment,
    shipmentsCount,
    createShipment,
    updateShipment,
    isUpdatingShipment,
    isSuccessPurchaseShipment,
    isSuccessShipmentById,
    isSuccessPaymentMethod,
    downloadShipmentLabels,
    labels,
    generateShipment,
    isGeneratingShipment,
    shipment,
    purchaseShipment,
    getShipmentById,
    isPurchasingShipment,
    createStripePayment,
    deleteShipment,
    isDeletingShipment,
    isCreatingShipment,
    shippingDetails,
    applyShipmentDiscount,
    isApplyingShipmentDiscount,
    generateShipmentError,
    shipmentConstant,
    createAnotherShipment,
    isCreatingAnotherShipment,
    createAnotherShipmentId,
    setCreateAnotherShipmentId,
  } = useShipments(filter, queries)

  const {discounts, applyDiscount, isApplyingDiscount} = useDiscounts()

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    const sortDirection = isAsc ? 'desc' : 'asc'
    const sortBy = property === 'listPriceRange' ? 'price' : property

    setOrder(sortDirection)
    setOrderBy(property)
    if (sortDirection !== order || orderBy !== property) {
      setQueries({
        ...filter,
        ...queries,
        sortBy,
        sortDirection,
      })
    }
  }

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const handleChangePage = (event, newPage) => {
    setFilter((prevState) => ({
      ...prevState,
      page: newPage,
      skip: prevState.take * newPage,
    }))
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setFilter({...filter, page: 0})
  }

  const onSaveChanges = async (p) => {
    if (p?.createShipment) {
      await createShipment(p?.createShipment)
      if (onCreate) {
        await onCreate()
      }
      setModalType(ModalType.PACKAGE_DETAILS)
    }
    if (p?.createAnotherShipment) {
      await createAnotherShipment({id: createAnotherShipmentId, ...p?.createAnotherShipment})
      setModalType(ModalType.PURCHASE_LABEL)
    } else if (p?.generateShipment) {
      if (parcel?.name) delete parcel.name

      let signature_confirmation = undefined
      if (p?.generateShipment?.signature_confirmation) {
        if (p?.generateShipment?.signature_confirmation?.value) {
          signature_confirmation = 'STANDARD'
        }
      } else {
        signature_confirmation = shipment?.shippo?.shipment?.extra?.signature_confirmation
      }

      let insurance = undefined
      if (p?.generateShipment?.insurance) {
        if (p?.generateShipment?.insurance?.value) {
          insurance = p?.generateShipment?.insurance?.value
        }
      } else {
        insurance = shipment?.shippo?.shipment?.extra?.insurance
      }

      const payload = {
        address_from: mw.createAddress(undefined, addresses?.sender),
        address_to: mw.createAddress(undefined, addresses?.recipient),
        parcels: [parcel],
        extra: {
          insurance: insurance,
          signature_confirmation: signature_confirmation,
        },
        async: false,
      }
      await generateShipment({payload, senderId: user.id, recipientId})
      setModalType(ModalType.PURCHASE_LABEL)
    } else if (p?.purchase) {
      if (!user?.private?.customerId || !user?.private?.paymentMethodId) {
        setModalType(ModalType.PAYMENT_METHOD)
        return
      }
      const payload = {
        ...p?.purchase,
        payment: {
          paymentMethodId: user?.private?.paymentMethodId,
          name: billingDetails?.name || user?.company?.name,
          email: user?.email,
          customerId: user?.private?.customerId,
        },
      }
      const response = await purchaseShipment(payload)
      if (response === false) return
      setModalType(ModalType.LABEL_PURCHASED)
    } else if (p?.createAnother) {
      await getShipmentById(p?.createAnother)
      setModalType(ModalType.PURCHASE_LABEL)
    } else if (p?.paymentMethod) {
      if (!stripe || !elements) return

      const cardElement = elements.getElement(CardNumberElement)
      if (cardElement && !hasMop) {
        await createStripePayment({cardElement, billingDetails, email: user?.email, stripe})
      }

      userRefech()
    }
  }

  const onSelectShipment = (transactionId) => {
    const arr = transactions
    const index = arr.findIndex((s) => s === transactionId)
    if (index > -1) {
      pullAt(arr, index)
    } else {
      arr.push(transactionId)
    }
    setTransactions(arr)
    forceUpdate()
  }

  useEffect(() => {
    if (isSuccessShipmentById) {
      if (isEmpty(shipment?.recipientAddress) || isEmpty(shipment?.senderAddress || shipment?.parcel)) {
        return
      }
      setAddresses({
        recipient: {
          ...shipment?.recipientAddress,
        },
        sender: {
          ...shipment?.senderAddress,
        },
      })
      setParcel(Object.keys(shipment?.parcel || {}).length ? shipment?.parcel : defaultParcelFields)
    }
  }, [shipment, isSuccessShipmentById])

  useEffect(() => {
    if (isSuccessPaymentMethod) {
      setModalType(ModalType.PURCHASE_LABEL)
    }
  }, [isSuccessPaymentMethod])

  useEffect(() => {
    if (modalType == -1) {
      setShipment(null)
      setParcel(defaultParcelFields)
      setAddresses(defaultAddressesFields)
    }
  }, [modalType])

  useEffect(() => {
    if (isSuccessPurchaseShipment) {
      if (hasMop) {
        // setModalType(ModalType.LABEL_PURCHASED)
      } else {
        setModalType(ModalType.PAYMENT_METHOD)
      }
    }
  }, [shipment, isSuccessPurchaseShipment])

  useEffect(() => {
    if (labels?.url && !isEmpty(transactions)) {
      window.open(labels?.url, '_blank')
      setTimeout(() => {
        setTransactions([])
      }, 1000)
    }
  }, [labels])

  return (
    <ShippingModals
      {...{
        consignmentBatchItems,
        addresses,
        billingDetails,
        consignee,
        modalType,
        payload,
        parcel,
        shipmentType,
        setRecipientId,
        onSaveChanges,
        isGeneratingShipment,
        isPurchasingShipment,
        selectedLocation,
        setAddresses,
        setBillingDetails,
        setConsignee,
        setModalType,
        setPayload,
        setShipmentType,
        setSelectedLocation,
        createShipment,
        setParcel,
        shipment,
        updateShipment,
        isUpdatingShipment,
        isCreatingShipment,
        shippingDetails,
        discounts,
        applyShipmentDiscount,
        isApplyingShipmentDiscount,
        applyDiscount,
        isApplyingDiscount,
        generateShipmentError,
        shipmentConstant,
        createAnotherShipment,
        isCreatingAnotherShipment,
        createAnotherShipmentId,
        setCreateAnotherShipmentId,
      }}
      isLoading={isLoading || isLoadingGetShipmentById}
      // shipment={shipmentId ? shipments?.find((s) => s?.id === shipmentId) : shipment}
    />
  )
}

export default ShippingPrompt
