export const options = [
  {value: 'High-Top', label: 'High-Top'},
  {value: 'Low-Top', label: 'Low-Top'},
  {value: 'Boots', label: 'Boots'},
  {value: 'Casual Leather Shoes', label: 'Casual Leather Shoes'},
  {value: 'Sandals', label: 'Sandals'},
  {value: 'Slip-ons', label: 'Slip-ons'},
  {value: 'Formal Shoes', label: 'Formal Shoes'},
]

export const accessories_options = [
  {value: 'Hats', label: 'Hats'},
  {value: "Women's Bags", label: "Women's Bags"},
  {value: "Men's Bags", label: "Men's Bags"},
]

export const apparel_options = [
  {value: 'Jeans', label: 'Jeans'},
  {value: 'Hoodies/Sweatshirts', label: 'Hoodies/Sweatshirts'},
  {value: 'Coats, Jackets & Vests', label: 'Coats, Jackets & Vests'},
  {value: 'Pants', label: 'Pants'},
  {value: 'Shirts', label: 'Shirts'},
  {value: 'Shorts', label: 'Shorts'},
  {value: 'Socks', label: 'Socks'},
  {value: 'Sweaters', label: 'Sweaters'},
]

const subCategoryOptions = {
  options,
  accessories_options,
  apparel_options,
}

export default subCategoryOptions
