import React from 'react'
import {Box, Typography, Stack, LinearProgress} from '@mui/material'
import {CopytStyledButton} from 'views_v2/lib/snippets'
import {ModalType} from 'enums'

const MdlConsignmentBatchShippingPrompt = (p) => {
  const {onSaveChanges, setModalType, isCreatingShipment, consignmentBatchItems} = p

  return (
    <Box>
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Shipping
          </Typography>
        </Box>
      </Box>

      {isCreatingShipment ? <LinearProgress color="secondary" /> : <Stack height="4px"></Stack>}

      <Box className="modal-body" p={0}>
        <Stack p={2} fontWeight={800}>
          <Typography>Would you like to create a shipping label for your items?</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={2}
          pb={2}
          borderTop="1px solid #eee"
          spacing={2}
        >
          <CopytStyledButton
            disabled={isCreatingShipment}
            variant="outline"
            color="primary"
            onClick={async () => {
              setModalType(ModalType.UNDEFINED)
            }}
          >
            NO
          </CopytStyledButton>
          <CopytStyledButton
            variant="contained"
            color="primary"
            disabled={isCreatingShipment}
            style={{width: '150px', borderRadius: '4px'}}
            onClick={async () => {
              await onSaveChanges({
                createShipment: {
                  productIds: [...consignmentBatchItems],
                  type: 'CONSIGNMENT',
                  method: 'PROMPT',
                  provider: 'SHIPPO',
                },
              })
            }}
          >
            {isCreatingShipment ? 'Creating label' : 'CREATE LABEL'}
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlConsignmentBatchShippingPrompt
