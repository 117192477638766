import React, {useState} from 'react'
import {
  Box,
  FormControlLabel,
  Typography,
  Stack,
  RadioGroup,
  Radio,
  Tooltip,
  LinearProgress,
} from '@mui/material'

import {shipment_type_options} from 'assets/data'
import {_replaceWildCards} from 'util/string_utils'
import {CopytLabel, CopytStyledButton, FieldGroup} from 'views_v2/lib/snippets'
import {ModalType} from 'enums'
import {CopytTooltip} from 'views_v2/lib/snippets'

const MdlCreateLabel = (props) => {
  const {
    onCloseModal,
    shipmentType,
    setShipmentType,
    setModalType,
    onSaveChanges,
    createAnotherShipmentId,
    isCreatingAnotherShipment,
    isCreatingShipment,
  } = props

  const onContinue = async () => {
    switch (shipmentType) {
      case 'consignment':
        setModalType(ModalType.SELECT_APPROVED_ITEMS)
        break
      case 'order-fulfillment':
        setModalType(ModalType.ORDER_FULFILLMENT)
        break
      case 'purchase':
        setModalType(ModalType.PURCHASE)
        break
      default:
        if (createAnotherShipmentId) {
          await onSaveChanges({
            createAnotherShipment: {
              productIds: [],
              method: 'DIRECT',
              type: 'OTHER',
              provider: 'SHIPPO',
            },
          })
        } else {
          await onSaveChanges({
            createShipment: {
              productIds: [],
              method: 'DIRECT',
              type: 'OTHER',
              provider: 'SHIPPO',
            },
          })
        }
        break
    }
  }

  return (
    <Box padding="0 !important">
      <Box className="modal-title">
        <Box className="modal__heading" mx="0px !important">
          <Typography variant="h1" textAlign="left">
            Create Label
          </Typography>
        </Box>
      </Box>

      {isCreatingAnotherShipment ? <LinearProgress color="secondary" /> : <Stack height="4px"></Stack>}

      <Box className="modal-body" padding={0} my={2}>
        <Stack direction="column" px={2} mb={4}>
          <CopytLabel style={{fontSize: '0.9rem', marginTop: '10px', marginBottom: '10px'}}>
            Select Shipment Type
          </CopytLabel>
          <RadioGroup
            size="small"
            value={shipmentType}
            onChange={(e) => {
              setShipmentType(e.target.value)
            }}
          >
            {shipment_type_options.map((s) => {
              let label = ''

              switch (s?.value) {
                case 'other':
                  label = 'Create a label for another purpose.'
                  break
                case 'order-fulfillment':
                  label = 'Create a label for an item you sold.'
                  break
                case 'purchase':
                  label = 'Create a label for an item you bought.'
                  break
                default:
                  break
              }

              return (
                <CopytTooltip key={s.value} title={label} placement="right">
                  <Box width="fit-content">
                    <FormControlLabel
                      sx={{ml: 0}}
                      key={s?.value}
                      value={s.value}
                      disabled={isCreatingAnotherShipment || isCreatingShipment}
                      control={<Radio size="small" color="secondary" />}
                      label={s?.label}
                      componentsProps={{
                        typography: {
                          sx: {
                            marginLeft: '8px',
                            width: 'fit-content',
                          },
                        },
                      }}
                    />
                  </Box>
                </CopytTooltip>
              )
            })}
          </RadioGroup>
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          pr={2}
          pt={2}
          borderTop="1px solid #eee"
          spacing={2}
        >
          <CopytStyledButton
            disabled={isCreatingAnotherShipment || isCreatingShipment}
            variant="outline"
            color="primary"
            onClick={onCloseModal}
          >
            Cancel
          </CopytStyledButton>
          <CopytStyledButton
            disabled={isCreatingAnotherShipment || isCreatingShipment}
            variant="contained"
            color="primary"
            onClick={onContinue}
          >
            Continue
          </CopytStyledButton>
        </Stack>
      </Box>
    </Box>
  )
}

export default MdlCreateLabel
