import React from 'react'
import {styled} from '@mui/material/styles'
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip'

const CopytTooltip = styled(
  ({className, arrow = true, backgroundColor, color, maxWidth, maxHeight, children, ...props}) => {
    const childElement = typeof children === 'string' ? <span>{children}</span> : children

    return (
      <Tooltip {...props} arrow={arrow} classes={{popper: className}}>
        {childElement}
      </Tooltip>
    )
  },
)(({theme, backgroundColor, color, maxWidth, maxHeight}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: backgroundColor || 'var(--primary)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '0.9em',
    maxWidth: maxWidth || 200,
    maxHeight: maxHeight || 'none',
    overflowY: maxHeight ? 'auto' : 'visible',
    overflowX: 'hidden',
    backgroundColor: backgroundColor || 'var(--primary)',
    color: color || 'white',
    whiteSpace: 'pre-wrap',
  },
  [`&.${tooltipClasses.popper}`]: {
    zIndex: '9999999 !important',
  },
}))

export default CopytTooltip
